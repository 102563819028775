<template v-if="model && model.link.href">
  <a
    v-if="model && model.url"
    class="group relative flex grow flex-col justify-between rounded-xl"
    :href="model.url"
    :external-link="true"
    @click.capture="$emit('trackHit', query, model.hitId, trackId)"
  >
    <div
      class="bg-light-blue-100 relative aspect-video w-full flex-shrink-0 overflow-hidden rounded-xl transition-all duration-300 ease-in-out group-hover:shadow-lg"
    >
      <shared-image
        v-if="imageModel"
        :model="imageModel"
        class="h-full w-full object-cover"
        format="?width=540&height=276&format=png&oldformat=webp&compand=true"
      />
      <div
        v-if="isDateWithinOneYear(model.date)"
        class="absolute bottom-2 left-2 w-fit rounded-lg bg-black/60 px-2 py-1 text-xs text-white"
      >
        {{ model.date }}
      </div>
    </div>
    <div
      v-if="model.title || model.description || model.duration"
      class="flex h-full w-full flex-col justify-between space-y-2 p-2"
    >
      <div>
        <div :class="{ 'flex items-center justify-between': model.duration }">
          <span
            v-if="!props.options?.hideTitle && model.title"
            class="text-grey-800 group-hover:text-light-blue-600 line-clamp-2 text-base font-medium transition-all duration-300"
          >
            {{ model.title }}
          </span>
          <div v-if="model.duration" class="text-grey-600 text-xs font-normal leading-none">
            {{ model.duration }}
          </div>
        </div>
        <div
          v-if="!props.options?.hideDescription && model.description"
          class="text-grey-700 mt-2 line-clamp-4 text-sm font-normal"
        >
          {{ model.description }}
        </div>
      </div>

      <div
        class="flex"
        :class="model.authors || model.authorNames ? 'justify-between' : 'justify-end'"
      >
        <span v-if="model.authorNames" class="text-grey-700 text-xs font-medium">
          {{ model.authorNames[0] }}
        </span>

        <tag
          v-if="contentTypeTag"
          :tag="contentTypeTag"
          class="badge flex w-fit items-center justify-center rounded-xl px-2 py-0.5 text-xs font-medium"
        />
      </div>
    </div>
  </a>
</template>

<script setup lang="ts">
import { ImageProp } from '@shared/types/shared'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { isDateWithinOneYear } from '@TodayInTheWord/scripts/date-util'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import { computed } from 'vue'

const emit = defineEmits(['trackHit'])

const dateTag = computed(() => {
  if (!props.model.displayDate) {
    return null
  }

  return {
    displayName: props.model.displayDate,
    tagColor: '',
  }
})

const contentTypeTag = computed(() => {
  if (!props.model.contentType) {
    return null
  }

  return {
    displayName: props.model.contentType,
    tagColor: 'dark-blue',
  }
})

const searchStore = useSearchPageStore()
const trackId = computed(() => {
  return searchStore?.searchResults?.trackId
})

const query = computed(() => {
  return searchStore?.searchResults?.query
})

const imageModel = computed((): ImageProp => {
  if (typeof props.model.image === 'string') {
    return {
      url: props.model.image,
      imageAlt: props.model.imageAlt,
    }
  }
  return props.model.image
})

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
})
</script>

<style lang="scss">
.badge {
  color: #0c4a6e;
  background: #e0f2fe;
}
</style>
