<template>
  <div
    v-if="props.podcastData && props.podcastData.streamUrl"
    class="audio-player flex w-full flex-col space-y-3 py-4"
    :class="
      isActive
        ? 'from-grey-50 from-1% via-light-blue-50 to-grey-50 to-99% bg-gradient-to-r via-50%'
        : 'bg-grey-50'
    "
  >
    <div class="flex flex-row items-center justify-between lg:flex-nowrap lg:justify-normal">
      <div class="lg:order-none">
        <podcast-play-pause-button :podcast="props.podcastData" />
      </div>

      <div class="mb-3 flex w-full flex-col lg:order-none lg:mb-0">
        <div class="flex justify-between">
          <div
            v-if="props.podcastData?.title"
            class="title text-light-blue-900 text-base font-medium leading-6"
          >
            {{ props.podcastData.title }}
          </div>
          <div class="timer text-grey-700 text-base font-normal leading-6">
            {{ formattedDuration }}
          </div>
        </div>
        <div
          v-if="props.podcastData?.description"
          class="text-light-blue-900 text-xs font-normal leading-5"
        >
          {{ props.podcastData.description }}
        </div>
        <div v-if="props.podcastData?.date" class="text-grey-600 text-xs font-normal leading-5">
          {{ props.podcastData.date }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { formatAudioTime } from '@TodayInTheWord/api/audio-time-formatter'
import { useMonthlyStudyPodcastStore } from '@TodayInTheWord/stores/monthly-study/monthly-study-podcast-store'
import { computed } from 'vue'
import { PodcastProps } from '../types/devotional-monthly-study-types'
import PodcastPlayPauseButton from './podcast-play-pause-button.vue'

interface podcastsSectionProps {
  podcastData?: PodcastProps
}

const props = defineProps<podcastsSectionProps>()

const podcastStore = useMonthlyStudyPodcastStore()

const isActive = computed(() => {
  return podcastStore.activePodcast?.streamUrl === props.podcastData?.streamUrl
})

const formattedDuration = computed(() => {
  return formatAudioTime(props.podcastData?.duration)
})
</script>
