<template>
  <section
    v-if="model"
    v-bind:id="model.clientID"
    :class="{
      'md:aspect-[21/9]': model.imageAspectRatio === '21/9',
      'md:aspect-[16/9]': model.imageAspectRatio === '16/9' || !model.imageAspectRatio,
      'md:aspect-[3/1]': model.imageAspectRatio === '3/1',
      'bg-center': model.imagePosition === 'center' || !model.imagePosition,
      'bg-bottom': model.imagePosition === 'bottom',
      'bg-left-bottom': model.imagePosition === 'bottom-left',
      'bg--right-bottom': model.imagePosition === 'bottom-right',
    }"
    class="TITW tri-feature-hero-block bg-light-blue-900 relative flex min-h-[550px] flex-col justify-center bg-cover bg-no-repeat md:min-h-[auto]"
    :style="computeBackgroundImage"
  >
    <div
      class="container mx-auto flex flex-col space-y-6 px-6 py-4 md:flex-row md:space-y-0"
      :class="{
        'items-center text-center': model.contentTextAlignment === 'center',
        'items-end text-right': model.contentTextAlignment === 'right',
      }"
    >
      <div
        class="bg-grey-50/[0.6] mr-12 flex min-h-[324px] w-full flex-col justify-center rounded-3xl p-8 backdrop-blur-[6px] md:min-h-[659px] md:w-1/2"
      >
        <h1
          v-if="model.heading"
          class="text-grey-800 mb-8 line-clamp-2 text-2xl font-bold md:mb-6 md:text-5xl md:leading-tight"
        >
          {{ model.heading }}
        </h1>
        <p v-if="model.subheading" class="text-grey-700 mb-8 line-clamp-4 text-base md:text-2xl">
          {{ model.subheading }}
        </p>
        <div
          v-if="model.buttons && model.buttons.length > 0"
          class="flex items-center"
          :class="{
            'justify-center': model.contentTextAlignment === 'center',
            'justify-end': model.contentTextAlignment === 'right',
          }"
        >
          <s-link
            v-for="(button, index) in featuredButtons"
            :key="index"
            :href="button.href"
            :target="button.target"
            :size="button.buttonSize"
            :emphasis="button.buttonEmphasis"
            :style="button.buttonStyle"
            :external-link="button.isExternal"
            class="text-sm md:text-lg"
            :class="{
              'mr-4': model.contentTextAlignment === 'left' || !model.contentTextAlignment,
              'mx-2': model.contentTextAlignment === 'center',
              'ml-4': model.contentTextAlignment === 'right',
            }"
          >
            {{ button.text }}
          </s-link>
        </div>
      </div>
      <template v-if="!mediaStore.isMobile">
        <div
          v-if="model.subFeatures && model.subFeatures.length > 0"
          class="w-1/2 flex-col space-y-8 md:flex"
        >
          <div
            v-for="(subFeature, index) in sortedSubFeatures"
            :key="index"
            class="bg-grey-50/[0.6] text-grey-800 flex min-h-[315.5px] flex-col justify-end rounded-xl p-8 backdrop-blur-[6px]"
          >
            <h3 v-if="subFeature.title" class="mb-5 line-clamp-2 text-3xl font-bold">
              {{ subFeature.title }}
            </h3>
            <p v-if="subFeature.description" class="mb-7 line-clamp-4 text-lg">
              {{ subFeature.description }}
            </p>
            <s-link
              v-if="subFeature.link?.href"
              :size="'regular'"
              class="flex items-center text-base font-medium"
              :href="subFeature.link.href"
            >
              Read More
              <span>
                <ArrowRightIcon class="ml-[10px] h-4 w-4" />
              </span>
            </s-link>
          </div>
        </div>
      </template>

      <c2-slider
        v-if="mediaStore.isMobile && model.subFeatures && model.subFeatures.length > 0"
        :slide-total="sortedSubFeatures?.length"
        :slides-to-show="1"
        :slides-to-show-mobile="1"
        :slide-speed="500"
        :gutter="10"
        :media-mobile-query="768"
        :show-overflow="true"
        :show-indicators="false"
        :next-btn-classes="'w-12 h-12 flex items-center justify-center absolute right-0 top-1/2 transform -translate-y-1/2'"
        :prev-btn-classes="'w-12 h-12 flex items-center justify-center absolute left-0 top-1/2 transform -translate-y-1/2'"
        :nav-classes="'flex justify-end mt-6 relative'"
        :show-button-nav="true"
        :button-nav-wrapper-classes="'max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center'"
        :button-classes="'relative h-6 w-[79px] mx-0.5 after:rounded after:block after:h-1 after:w-full after:bg-grey-300 after:absolute after:top-1/2 after:transform after:-translate-y-1/2'"
        :active-button-classes="'after:!bg-light-blue-900'"
        :show-next-prev-btns="false"
        class="relative -translate-x-4 transform pl-4"
      >
        <template v-slot:slide>
          <div v-for="(subFeature, index) in sortedSubFeatures" :key="index" class="flex">
            <div
              class="bg-grey-50/[0.6] text-grey-800 flex min-h-[158px] w-full flex-col justify-start rounded-3xl px-4 pb-7 pt-4 backdrop-blur-[6px]"
            >
              <h3 v-if="subFeature.title" class="mb-5 line-clamp-2 text-3xl font-bold">
                {{ subFeature.title }}
              </h3>
              <p v-if="subFeature.description" class="mb-0 line-clamp-4 text-sm">
                {{ subFeature.description }}
              </p>
              <s-link
                v-if="subFeature.link?.href"
                :size="'regular'"
                class="mb-10 flex items-center text-base font-medium"
                :href="subFeature.link.href"
              >
                Read More
                <span>
                  <ArrowRightIcon class="ml-2.5 h-4 w-4" />
                </span>
              </s-link>
            </div>
          </div>
        </template>
      </c2-slider>

      <s-button
        v-if="model.videoShareLink || model.videoUrl"
        ref="playButton"
        :size="'regular'"
        :emphasis="'bold'"
        :style="'primary'"
        class="absolute left-1/2 top-1/2 flex h-[72px] w-[72px] -translate-x-1/2 -translate-y-1/2 transform items-center justify-center"
        @click="openModal()"
      >
        <span class="sr-only">Play Video</span>
        <span class="flex h-6 w-6 items-center justify-center">
          <PlayIcon class="w-full" />
        </span>
      </s-button>
    </div>

    <c2-modal
      v-if="model.videoShareLink || model.videoUrl"
      ref="heroModal"
      :background-color="'transparent'"
      :background-overlay-color="'rgba(0,0,0,0.8)'"
      :close-btn-classes="'flex items-center justify-center h-12 w-12 absolute top-0 right-0'"
      :header-classes="'flex items-end justify-between min-h-[48px] px-4 relative'"
      :wrapper-classes="'bg-white p-4'"
      :max-width="1296"
      class="relative h-0 overflow-hidden"
      @modal-opened="toggleShowVideo(true)"
      @modal-closed="toggleShowVideo(false)"
    >
      <template v-slot:close-btn>
        <span class="sr-only">Close Modal</span>
        <span class="h-6 w-6"><XMarkIcon class="text-white" /></span>
      </template>
      <template v-slot:body>
        <template v-if="showVideoSrc">
          <div
            v-if="model.videoShareLink && !model.videoUrl"
            class="iframe-body relative aspect-[16/9] w-full overflow-hidden rounded-xl bg-white"
            v-html="model.videoShareLink"
          ></div>

          <div v-if="model.videoUrl" class="relative aspect-[16/9] w-full">
            <video controls class="w-full" ref="modalVideo">
              <source :src="model.videoUrl" type="video/mp4" />
            </video>
          </div>
        </template>
      </template>
    </c2-modal>

    <template v-if="model.videoShareScript">
      <div id="custom-video-script"></div>
    </template>
  </section>
</template>

<script setup lang="ts">
import { ArrowRightIcon, PlayIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import C2Modal from 'uikit/c2-modal/c2-modal.vue'
import C2Slider from 'uikit/c2-slider/c2-slider.vue'
import { computed, ref } from 'vue'
import { TriFeatureHeroBlockProps } from './types/hero-types'

const props = defineProps<TriFeatureHeroBlockProps>()

const mediaStore = useMediaSizeStore()
const heroModal = ref()
const modalVideo = ref()
const playButton = ref()

const computeBackgroundImage = computed(() => {
  var imageURL = props.model?.imageUrl
  var imageWidth = 1440
  if (mediaStore.isMobile) {
    imageURL = props.model?.mobileImageUrl
    imageWidth = 720
  }
  var imageHeight = 0
  if (props.model?.imageAspectRatio === '21/9') {
    imageHeight = (imageWidth * 9) / 21
  } else if (props.model?.imageAspectRatio === '3/1') {
    imageHeight = (imageWidth * 1) / 3
  } else {
    imageHeight = (imageWidth * 9) / 16
  }
  imageHeight = Math.round(imageHeight)
  if (imageURL)
    return `background-image: url('${imageURL}?width=${imageWidth}&height=${imageHeight}&format=png&oldformat=webp&compand=true')`
  else return ''
})

const sortedSubFeatures = computed(() => {
  if (props.model?.subFeatures && props.model?.subFeatures.length > 2) {
    return props.model?.subFeatures.slice(0, 2)
  } else {
    return props.model?.subFeatures
  }
})

const featuredButtons = computed(() => {
  if (props.model?.buttons && props.model?.buttons.length > 2) {
    return props.model?.buttons.slice(0, 2)
  } else {
    return props.model?.buttons
  }
})

const showVideoSrc = ref(true)
function openModal() {
  heroModal.value.openModal()
}

const toggleShowVideo = (bool: boolean) => {
  showVideoSrc.value = bool

  if (showVideoSrc.value) {
    createScript()
  } else {
    pauseAndReturnFocus()
  }
}
const createScript = () => {
  //clear any previous scripts
  var prevScript = document.querySelector('#custom-video-script')
  if (prevScript !== null) {
    prevScript.innerHTML = ''
    var script = document.createElement('script')
    prevScript.appendChild(script)
    if (props.model?.videoShareScript) {
      script.innerHTML = props.model?.videoShareScript
    }
  }
}
const pauseAndReturnFocus = () => {
  const videos = [...document.getElementsByTagName('video')]
  videos.forEach((vid) => {
    vid.pause()
  })
  const iframeVideo = heroModal.value.$el.querySelector('iframe')
  if (iframeVideo) {
    let src = iframeVideo.src
    iframeVideo.src = src
  }

  playButton.value.$el.focus()
}
</script>

<style lang="scss">
.c2-modal {
  .iframe-body {
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
