<template>
  <section v-if="props.model" class="space-y-6">
    <SectionHeading v-if="props.model.heading" :heading="props.model.heading" />

    <div v-if="props.model.cards?.length">
      <div class="grid grid-cols-1 gap-6 md:grid-cols-3">
        <s-image-link-card
          v-for="(card, index) in loadedCards"
          :card="card"
          :key="index"
          :date-format="props.dateFormat"
          :options="props.options"
          class="col-span-1 !w-full"
        />
      </div>
      <s-button
        v-if="props.model.cards.length > 3 && mediaSizeStore.isMobile && !state.btnOpen"
        @click="showCards"
        :size="'regular'"
        :style="'primary'"
        :emphasis="'bold'"
        class="w-full"
      >
        Show More
      </s-button>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ImageLinkCardDisplayOptions } from '@brand/types/shared'
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import { RelatedArticlesProps } from '@TodayInTheWord/components/pages/article-index/types/article-detail-types'
import sButton from '@TodayInTheWord/components/shared/s-button.vue'
import SImageLinkCard from '@TodayInTheWord/components/shared/s-image-link-card.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { computed, onMounted, ref } from 'vue'

interface RelatedContentProps {
  model?: RelatedArticlesProps
  dateFormat?: string
  options?: ImageLinkCardDisplayOptions
}

const props = defineProps<RelatedContentProps>()
const mediaSizeStore = useMediaSizeStore()

const state = ref({
  isActive: 0,
  btnOpen: false,
  totalCards: 0,
  cardsToShow: 3,
})

function showCards() {
  state.value.cardsToShow += 3
  if (state.value.totalCards) {
    if (state.value.cardsToShow >= state.value.totalCards) {
      state.value.btnOpen = true
    }
  }
}

const loadedCards = computed(() => {
  if (props.model?.cards?.length) {
    if (mediaSizeStore.isMobile) {
      return props.model.cards.slice(0, state.value.cardsToShow)
    } else {
      return props.model.cards.slice(0, state.value.totalCards)
    }
  }
})

onMounted(() => {
  if (props.model?.cards?.length) {
    state.value.totalCards = props.model.cards.length
  }
})
</script>
