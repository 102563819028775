import { DateTime } from 'luxon'

function getCookie(cname: any) {
  let name = `${cname}=`
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')

  for (let item of ca) {
    let c = item.trim()

    if (c.startsWith(name)) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}

export function getDateTime(): DateTime {
  const dateCookie = getCookie('__c2_MBI_CurrentDate')
  if (!dateCookie) {
    return DateTime.now()
  }

  const customDate = DateTime.fromISO(dateCookie)
  if (customDate.isValid) {
    return customDate
  }

  return DateTime.now()
}
