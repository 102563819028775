<template>
  <section
    :class="{
      'TITW rich-text-block container mx-auto': props.hasContainer,
    }"
    v-bind:id="props.model?.clientID"
  >
    <template v-if="props.classOverride">
      <div
        v-if="props.model?.richTextField"
        ref="richText"
        class="content"
        :class="props.classOverride"
        v-html="props.model?.richTextField"
      />
    </template>
    <template v-else>
      <div
        v-if="props.model?.richTextField"
        ref="richText"
        class="prose prose-sm prose-headings:text-grey-700 md:prose lg:prose-xl content !max-w-none"
        v-html="props.model?.richTextField"
      />
    </template>
  </section>
</template>

<script setup lang="ts">
import { useBibleGatewayStore } from '@TodayInTheWord/stores/bible-gateway'
import { computed, onMounted, ref } from 'vue'
import { RichTextBlockProps } from './types/rich-text-block-types'

const biblegatewayApiStore = useBibleGatewayStore()

const props = defineProps<RichTextBlockProps>()

const richText = ref()

onMounted(() => {
  if (richText) {
    const bibleSnippets = richText.value?.querySelectorAll('.bible-ref')

    if (bibleSnippets) {
      biblegatewayApiStore.findReferences(bibleSnippets)
    }
  }
})
</script>

<style lang="scss">
float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

p:has(> img.align-center) {
  text-align: center;
}

p > img.align-center {
  margin: auto;
  border-radius: 0.75rem;
}

img.float-left {
  float: left;
  margin: 0 1.5rem 1rem 0;
  border-radius: 0.75rem;
}

img.float-right {
  float: right;
  margin: 0 0 1rem 1.5rem;
  border-radius: 0.75rem;
}

ul,
ol {
  list-style-position: inside;
}

.prose ul li,
.prose ol li {
  list-style-position: outside;
}

@media screen and (min-width: 1024px) {
  .content {
    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 600;
    }

    h3 {
      font-size: 1.5rem;
      line-height: 1.75rem;
      font-weight: 500;
    }

    h4 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
    }

    h5 {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 400;
    }

    h2,
    h3,
    h4,
    h5 {
      color: #1f2937;
    }
  }
}
</style>
