import PageComponentSelector from '@TodayInTheWord/epi/page-component-selector.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMonthlyStudyPodcastStore } from '@TodayInTheWord/stores/monthly-study/monthly-study-podcast-store'
import { createMemoryHistory, createRouter, createWebHistory, START_LOCATION } from 'vue-router'

interface PathPosition {
  url: any
  scrollPosition: number
}

const router = createRouter({
  history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
  routes: [
    {
      name: 'page-component-selector',
      path: '/:pathMatch(.*)*',
      component: PageComponentSelector,
      props: (route) => ({ key: route.fullPath }),
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth', // Changed to 'smooth'
      }
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  },
})

if (typeof window !== 'undefined') {
  const scrollPositions = new Map<string, PathPosition>()
  const saveScrollPosition = (from: any) => {
    const scrollPosition = window.scrollY
    const pathPosition: PathPosition = {
      url: from,
      scrollPosition: scrollPosition,
    }
    scrollPositions.set(from.path, pathPosition)
  }

  const restoreScrollPosition = (to: any) => {
    let savedPosition = scrollPositions.get(to.path)

    if (savedPosition) {
      if (to.path !== savedPosition?.url?.path) {
        savedPosition = undefined
      }
      if (to.query.p !== savedPosition?.url?.query?.p) {
        savedPosition = undefined
      }
    }

    if (savedPosition) {
      window.scrollTo({ top: savedPosition.scrollPosition, behavior: 'smooth' }) // Changed to 'smooth'
      savedPosition = undefined
    } else if (to.hash) {
      const targetElement = document.querySelector(to.hash)
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' }) // Changed to 'smooth'
        savedPosition = undefined
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        savedPosition = undefined
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      savedPosition = undefined
    }
  }

  router.beforeEach(async (to, from) => {
    if (from === START_LOCATION || to.fullPath === from.fullPath) {
      return
    }

    saveScrollPosition(from)

    const epiPageContextStore = useEpiPageContextStore()
    await epiPageContextStore.fetchPageContext(to.fullPath)

    const podcastStore = useMonthlyStudyPodcastStore()
    podcastStore.resetState()

    restoreScrollPosition(to)
  })

  router.afterEach((to: any, from: any) => {
    const appInsights: any = window?.appInsights
    if (!appInsights) {
      return
    }

    const epiPageContext = useEpiPageContextStore()
    const pageViewTelemetry = {
      refUri: from?.fullPath,
      uri: to?.fullPath,
      pageType: epiPageContext.pageType,
      name: epiPageContext.metadata?.seoTitle,
    }

    if (epiPageContext.duration) {
      pageViewTelemetry.properties = {
        duration: epiPageContext.duration,
      }
    }

    appInsights.trackPageView(pageViewTelemetry)
  })
}

export default router
