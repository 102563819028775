<template>
  <s-link
    v-if="model.url"
    :class="class"
    :href="model.url"
    :external-link="isExternal"
    @click.capture="$emit('trackHit', query, model.hitId, trackId)"
  >
    <div
      class="bg-light-blue-100 relative aspect-[16/9] w-full flex-shrink-0 overflow-hidden rounded-xl transition-all duration-300 ease-in-out group-hover:shadow-lg"
    >
      <picture v-if="model.image" class="flex">
        <img
          :src="imageUrl"
          :alt="model.title"
          class="aspect-[16/9] h-full w-full overflow-hidden rounded-xl"
        />
      </picture>
      <div
        v-if="model.displayDate"
        class="absolute bottom-2 left-2 w-fit rounded-lg bg-black/60 px-2 py-1 text-xs text-white"
      >
        {{ model.displayDate }}
      </div>
    </div>
    <div
      v-if="model.title || model.description || model.duration"
      class="flex w-full flex-grow flex-col justify-between space-y-2 p-2"
    >
      <div class="flex flex-col justify-between">
        <div
          v-if="model.title || model.duration"
          class="flex items-center lg:mb-2"
          :class="model.title ? 'justify-between' : 'justify-end'"
        >
          <span
            v-if="model.title"
            class="text-grey-800 group-hover:text-light-blue-600 line-clamp-2 text-base font-medium transition-all duration-300 ease-in-out"
          >
            {{ model.title }}
          </span>
          <span v-if="model.duration" class="text-grey-600 ml-2 text-xs font-normal">{{
            model.duration
          }}</span>
        </div>
        <div
          v-if="model.description && !mediaSizeStore.isMobile"
          class="text-grey-700 line-clamp-3 text-sm font-normal"
        >
          {{ model.description }}
        </div>
      </div>
      <div class="flex items-center justify-start">
        <div
          v-if="model.categories && model.categories.length > 0"
          class="bullet-seperator flex items-center space-x-1 [&>:not(:first-child)]:before:mr-1"
        >
          <span
            v-for="(category, index) in computedCategories"
            :key="index"
            class="text-grey-500 text-xs font-normal"
          >
            {{ category }}
          </span>
        </div>
      </div>
    </div>
  </s-link>
</template>

<script setup lang="ts">
import SearchHitTracking from '@shared/api/search-hit-tracking'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import SLink from '@TodayInTheWord/components/shared/s-link.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import { computed } from 'vue'
import segmentHeader from './segment-header.vue'
import TopPageCard from './top-page-card.vue'

const mediaSizeStore = useMediaSizeStore()

const searchStore = useSearchPageStore()
const trackId = computed(() => {
  return searchStore?.searchResults?.trackId
})

const query = computed(() => {
  return searchStore?.searchResults?.query
})

const items = computed((): searchTypes.TitwSearchResultItemModel[] | null => {
  return searchStore?.searchResults?.results?.pages?.items ?? null
})

const isExternal = computed(() => {
  return props.model?.url && props.model.url.startsWith('http')
})

const imageUrl = computed(() => {
  if (props.model.image) {
    const hasQueryString = props.model.image.includes('?')
    console.log({ 'video item': props.model })
    return (
      props.model.image +
      (hasQueryString
        ? '&width=320&height=180&format=png&oldformat=webp'
        : '?width=320&height=180&format=png&oldformat=webp')
    )
  }
  return null
})

const computedCategories = computed(() => {
  return props?.model?.categories?.slice(0, 2)
})

const computedAuthors = computed(() => {
  if (props.model.authorNames) {
    return props.model.authorNames[0]
  }
})

const emit = defineEmits(['changeTab', 'trackHit'])

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
  class: {
    type: String,
    default:
      'group relative mx-auto mb-4 flex w-full grow flex-col justify-between rounded-xl px-3 md:w-1/3',
  },
})
</script>

<style lang="scss"></style>
