﻿<template>
  <footer
    v-if="footer"
    class="TITW s-footer bg-grey-100 text-grey-600 mt-8 pb-12 pt-8 md:mt-16 lg:pb-14 lg:pt-20"
  >
    <div class="container mx-auto">
      <div class="flex flex-col lg:flex-row lg:justify-between lg:pb-20">
        <div class="order-2 lg:order-1 lg:w-2/3">
          <desktop-nav v-if="footer.columns" :columns="footer.columns" />

          <mobile-accordion
            v-for="(column, columnIndex) in footer.columns"
            :key="columnIndex"
            :title="column.displayTitle ?? ''"
            :links="column.links"
            :hasTopBorder="columnIndex === 0"
          />
        </div>

        <div class="order-1 lg:w-1/3">
          <content-block v-if="footer.newsletterForm" :model="footer.newsletterForm">
          </content-block>
        </div>
      </div>

      <social-nav
        v-if="footer.socialMediaCollection && footer.socialMediaCollection.length > 0"
        class="mb-4 mt-8 lg:hidden"
        :social-media="footer.socialMediaCollection"
      />

      <div
        class="border-top-solid border-grey-200 border-y py-4 lg:flex lg:justify-between lg:py-11"
      >
        <div
          v-if="footer.mobileLogo || footer.desktopLogo"
          class="flex w-full max-w-[150px] items-center"
        >
          <a v-if="footer.homePageLink" :href="footer.homePageLink">
            <shared-image
              v-if="computedImage"
              :model="computedImage"
              format="?width=150&format=png&oldformat=webp&compand=true"
              role="img"
              aria-label="Back to home page"
            />
          </a>
        </div>

        <div class="lg:flex lg:items-center">
          <utility-nav v-if="footer.utilityLinks" :links="footer.utilityLinks" />

          <div v-if="footer.copyrightText" class="mt-4 text-xs lg:ml-14 lg:mt-0">
            &copy; {{ footer.copyrightText }} {{ currentYear }}
          </div>
        </div>
      </div>

      <div
        v-if="
          footer.missionStatementText ||
          (footer.socialMediaCollection && footer.socialMediaCollection.length > 0)
        "
        class="mt-8 flex items-center justify-between"
      >
        <p v-if="footer.missionStatementText" class="mb-0 w-full text-xs leading-[18px] lg:w-1/3">
          {{ footer.missionStatementText }}
        </p>

        <social-nav
          v-if="footer.socialMediaCollection && footer.socialMediaCollection.length > 0"
          class="hidden lg:flex"
          :social-media="footer.socialMediaCollection"
        />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import DesktopNav from '@TodayInTheWord/components/shared/footer/components/main-nav/desktop-nav.vue'
import MobileAccordion from '@TodayInTheWord/components/shared/footer/components/main-nav/mobile-accordion.vue'
import SocialNav from '@TodayInTheWord/components/shared/footer/components/social-nav.vue'
import UtilityNav from '@TodayInTheWord/components/shared/footer/components/utility-nav.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import ContentBlock from '@TodayInTheWord/epi/content-block.vue'
import { useFooterStore } from '@TodayInTheWord/stores/footer/footer'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const currentYear = new Date().getFullYear()
const footerStore = useFooterStore()
const { footer } = storeToRefs(footerStore)

const mediaStore = useMediaSizeStore()

const computedImage = computed(() => {
  if (mediaStore.isMobile && footer.value?.mobileLogo) {
    return footer.value?.mobileLogo
  } else {
    return footer.value?.desktopLogo
  }
})
</script>

<style lang="scss">
footer {
  .form-container {
    padding: 0;
    width: 100%;
    background-color: transparent;

    .EPiServerForms {
      .Form__Title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: theme('colors.grey.600');
        margin-bottom: 16px;
      }

      .Form__Status .Form__Status__Message {
        padding: 0;
        margin: 0;
      }

      /*.Form__Element__ValidationError {
        position: absolute;
      }*/

      .Form__Description {
        font-size: 14px;
        line-height: 20px;
        color: theme('colors.grey.700');
        margin-bottom: 16px;
      }

      > .Form__MainBody {
        margin-bottom: 32px;

        @media (min-width: 1024px) {
          margin-top: 34px;
          margin-bottom: 0;
        }

        > section {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 100%;
          gap: 20px;
          position: relative;

          > .Form__Element {
            flex-basis: 0;
            flex-grow: 1;

            &.FormSubmitButton,
            &.FormResetButton {
              flex-basis: 1;
              max-width: fit-content;
            }
          }
        }
      }

      .FormTextbox {
        margin: 0;
        flex: 1;
      }

      .Form__Element__Caption {
        font-weight: 600;
        color: #4b5563;
        font-size: 14px;
        line-height: 20px;

        &::after {
          content: '' !important;
        }
      }

      .Form__Element--NonData div p {
        margin-bottom: 0px;
      }
    }
  }

  .form-container {
    background-color: transparent;
  }

  &.mobile-form-section .form-container {
    padding: 0;
  }

  .EPiServerForms {
    textarea {
      width: 100%;
      height: 144px;
      padding: 12px;
      border: 1px solid #e5e7eb !important;
      border-radius: 12px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      background-color: #fff;
    }
    .Form__Status {
      .formSuccess:not(:first-of-type) {
        display: none;
      }
      .Form__Status__Message {
        &.hide {
          display: none;
        }
      }
    }

    .Form__MainBody {
      section.Form__Element {
        flex-wrap: wrap;
      }

      .Form__Element {
        display: flex;
        width: 100%;
        margin: 0;

        .FormChoice__Label {
          font-size: 16px;
          line-height: 24px;
          font-weight: normal;
          margin-bottom: 0;
        }

        .Form__Element__Caption,
        label {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        &.FormTextbox {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .FormTextbox__Input {
            width: 100%;
            height: 48px;
            padding: 12px;
            border: 1px solid #e5e7eb;
            border-radius: 12px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            background-color: #fff;
          }
        }

        .FormSubmitButton,
        input[type='reset'] {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: 48px;
          margin-top: 1em;
          margin-bottom: 0;
          padding: 8px 14px;
          background-color: #0c4a6e;
          border: 1px solid #0c4a6e;
          color: #fff;
          border-radius: 12px;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          cursor: pointer;
        }

        input[type='reset'] {
          background-color: #e0f2fe;
          border: 1px solid #e0f2fe;
          color: #0c4a6e;
          margin-right: 0;
          width: auto;
          align-self: flex-end;
        }

        .centered & {
          flex-direction: column;

          .FormSubmitButton {
            margin-top: 16px;
            margin-left: 0;
          }
        }

        .FormSubmitButton:hover {
          transition:
            0.3s background-color,
            0.3s border-color;
          border-color: #0369a1;
          background-color: #0369a1;
        }
      }

      @media (max-width: 1023px) {
        .Form__Element {
          flex-direction: column;

          .FormSubmitButton,
          input[type='reset'] {
            margin-top: 16px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
span svg image {
  width: 100%;
  max-width: 100%;
  height: auto;
}
</style>
