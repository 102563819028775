<template>
  <s-link
    v-if="card.link"
    :href="card.link.href"
    :external-link="card.link.isExternal"
    class="TITW resource-card-todayintheword group flex h-full w-full flex-col transition-all duration-300"
  >
    <div
      class="bg-light-blue-100 relative aspect-[16/9] w-full overflow-hidden rounded-xl transition-all duration-300 ease-in-out group-hover:shadow-lg"
    >
      <shared-image
        v-if="card.image"
        :model="card.image"
        class="aspect-video h-full w-full object-cover"
        format="?width=540&height=276&format=png&oldformat=webp&compand=true"
      />
      <div
        v-if="isDateWithinOneYear(card.displayDate)"
        class="absolute bottom-2 left-2 w-fit rounded-lg bg-black/60 px-2 py-1 text-xs text-white"
      >
        {{ card.displayDate }}
      </div>
    </div>

    <div
      v-if="card.title || card.description"
      class="flex w-full flex-grow flex-col justify-between space-y-2 p-2"
    >
      <div class="flex items-center justify-between">
        <span
          v-if="card.title"
          class="text-grey-800 group-hover:text-light-blue-600 line-clamp-2 text-base font-medium transition-all duration-300"
        >
          {{ card.title }}
        </span>
      </div>
      <div
        v-if="card.description"
        class="text-grey-700 line-clamp-3 min-h-16 flex-grow text-sm font-normal"
      >
        {{ card.description }}
      </div>
      <div v-else class="min-h-16 flex-grow text-sm font-normal">&nbsp;</div>
      <div class="flex items-center justify-between">
        <span v-if="card.authorName" class="text-grey-700 text-xs font-medium">{{
          card.authorName
        }}</span>
        <span v-else class="text-xs font-medium">&nbsp;</span>
        <tag
          v-if="card.articleType || card.type"
          class="rounded-xl px-2 py-0.5 text-xs"
          :tag="cardType"
        />
      </div>
    </div>
  </s-link>
</template>

<script setup lang="ts">
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { isDateWithinOneYear } from '@TodayInTheWord/scripts/date-util'
import { DateTime } from 'luxon'
import { computed } from 'vue'
import { ResourceCardTitw } from '../types/featured-resources-block-types'

interface ResourceCardTitwProps {
  card: ResourceCardTitw
}

const props = defineProps<ResourceCardTitwProps>()

const cardType = computed(() => {
  if (props.card.articleType) {
    return props.card.articleType
  } else {
    return props.card.type
  }
})
</script>
