<template>
  <input
    ref="audioSlider"
    type="range"
    class="audio-slider order-1"
    :style="progressStyle"
    :value="sliderTime"
    @input="seek"
    :min="0"
    :max="sliderDuration"
    :step="0.1"
  />
</template>

<script setup lang="ts">
// isCurrentMetadataLoaded
import { isDefined } from '@shared/api/type-utilities'
import { useMonthlyStudyPodcastStore } from '@TodayInTheWord/stores/monthly-study/monthly-study-podcast-store'
import { storeToRefs } from 'pinia'
import { computed, MaybeRefOrGetter, nextTick, onMounted, ref, watch, watchEffect } from 'vue'

const podcastStore = useMonthlyStudyPodcastStore()
const { currentTime, currentDuration, isCurrentMetadataLoaded } = storeToRefs(podcastStore)

const isMounted = ref(false)

const audioSlider = ref<HTMLInputElement | null>(null)
const sliderTime = ref(0)
const sliderDuration = ref(1)
const progress = ref(0)
watch([currentTime, currentDuration, isCurrentMetadataLoaded, isMounted], () => {
  if (!isMounted.value) {
    return
  }

  updateProgress(currentTime.value, currentDuration.value)
})

function updateProgress(currentTime?: number | null, currentDuration?: number) {
  if (!isDefined(currentTime) || !isDefined(currentDuration)) {
    return 0
  }

  if (currentDuration === 0) {
    sliderTime.value = 0
    sliderDuration.value = 1
    return 0
  }

  sliderTime.value = currentTime
  sliderDuration.value = currentDuration

  progress.value = (currentTime / currentDuration) * 100
  nextTick(() => {
    if (isDefined(audioSlider.value)) {
      audioSlider.value.valueAsNumber = sliderTime.value
    }
  })
}

const progressBackgroundStyle = ref('')
watch([progress, isMounted, isCurrentMetadataLoaded], () => {
  if (!isMounted.value || !isCurrentMetadataLoaded.value || progress.value == 0) {
    return ''
  }

  progressBackgroundStyle.value = `linear-gradient(to right, #0369a1 ${progress.value}%, #bae6fd ${progress.value}%)`
})

const progressStyle = computed(() => {
  return {
    background: progressBackgroundStyle.value,
  }
})

const seek = (event: Event) => {
  const seekTime = (event.target as HTMLInputElement).valueAsNumber
  podcastStore.seek(seekTime)
}

onMounted(() => {
  isMounted.value = true
  sliderDuration.value = currentDuration.value ?? 1
  sliderTime.value = currentTime.value ?? 0
})
</script>
