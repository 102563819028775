<template>
  <nav
    v-show="header && !headerStore.isMobile"
    class="TITW desktop-nav container mx-auto flex h-20 items-center"
  >
    <div class="container mx-auto flex h-20 items-center">
      <div v-if="header?.desktopLogo" class="desktopLogo w-full max-w-[150px]">
        <a :href="header.homePageLink ?? '/'">
          <shared-image
            :model="header.desktopLogo"
            format="?width=150&height=56&format=png&oldformat=webp&compand=true"
            role="img"
            aria-label="Back to home page"
            :preload="true"
          />
        </a>
      </div>

      <div class="static flex items-center">
        <PrimaryMenu></PrimaryMenu>
      </div>
      <SearchBar v-if="!epiStore.isSearchPage" />
      <ul class="utility-btns ml-auto flex space-x-4">
        <li v-if="header?.bibleReadingExperiencePageLink" class="bibleReadingButton">
          <s-link
            :size="'larger'"
            :href="header.bibleReadingExperiencePageLink.href"
            :target="header.bibleReadingExperiencePageLink.target"
            :external-link="header.bibleReadingExperiencePageLink.isExternal ?? true"
            :style="'bg-cyan-600 border-cyan-600 text-color-white p3 h-full'"
          >
            <bible-icon :fill-class="'fill-white'" />
            <span class="sr-only"> Bible Experience </span>
          </s-link>
        </li>
        <li v-if="header?.donateButton && header.donateButton.href" class="donateButton">
          <s-link
            :size="'larger'"
            :href="header.donateButton.href"
            :target="header.donateButton.target"
            :external-link="header.donateButton.isExternal ?? true"
            :style="'bg-cyan-600 border-cyan-600 text-color-white p3 h-full'"
            class="h-full pb-1.5 pt-1.5 !text-base"
          >
            {{ header.donateButton.text }}
          </s-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">
import BibleIcon from '@TodayInTheWord/components/icons/bible-icon.vue'
import PrimaryMenu from '@TodayInTheWord/components/shared/header/components/main-nav/desktop/primary-menu.vue'
import SearchBar from '@TodayInTheWord/components/shared/header/components/main-nav/desktop/search-bar.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'

const headerStore = useHeaderStore()
const header = headerStore.header
const epiStore = useEpiPageContextStore()
</script>
