﻿<template>
  <div class="container mx-auto">
    <nav v-if="links && links.length > 0 && !mediaStore.isMobile" class="p-1 lg:p-2">
      <c2-breadcrumbs class="flex flex-wrap items-center">
        <template v-for="(item, index) in links" :key="index">
          <template v-if="!item.isExternal">
            <c2-breadcrumb-item
              v-if="item.href"
              :href="item.href"
              class="mr-4 flex items-center"
              :ariaLabel="getLabel(index)"
            >
              <template v-slot:content>
                <template v-if="index === 0">
                  <s-hero-icon
                    :icon="item.icon"
                    :width="24"
                    :height="24"
                    class="flex h-6 w-6 items-center justify-center"
                  />
                </template>

                <template v-else>
                  {{ item.text }}
                </template>
              </template>

              <template v-slot:divider>
                <span class="text-grey-200 ml-4">
                  <ChevronRightIcon class="h-5 w-5" />
                </span>
              </template>
            </c2-breadcrumb-item>
            <span v-else class="mr-4 flex items-center">
              {{ item.text }}
              <span class="text-grey-200 ml-4">
                <ChevronRightIcon class="h-5 w-5" />
              </span>
            </span>
          </template>
          <template v-else>
            <a
              class="mr-4 flex items-center"
              :href="item.href"
              :target="item.target"
              :ariaLabel="getLabel(index)"
            >
              {{ item.text }}
              <span class="text-grey-200 ml-4">
                <ChevronRightIcon class="h-5 w-5" />
              </span>
            </a>
          </template>
        </template>

        <c2-breadcrumb-item class="mr-4 flex items-center" :is-current="true" href="#">
          <template v-slot:content>
            {{ currentPageName }}
          </template>
        </c2-breadcrumb-item>
      </c2-breadcrumbs>
    </nav>
    <nav v-if="mediaStore.isMobile">
      <s-link
        class="text-light-blue-900 flex flex-wrap items-center"
        :href="mobileItem?.href"
        :external-Link="mobileItem?.isExternal"
        :target="mobileItem?.target"
      >
        <span class="mr-2.5"><ArrowLeftIcon class="h-5 w-5" /></span>
        <span v-if="mobileItem?.text" class="text-sm font-medium leading-5">{{
          mobileItem?.text
        }}</span>
        <span v-else class="text-sm font-medium leading-5">{{ getMobileDefaultLabel(mobileItem?.href) }}</span>
      </s-link>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import SHeroIcon from '@TodayInTheWord/components/shared/s-hero-icon.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import C2BreadcrumbItem from 'uikit/c2-breadcrumbs/c2-breadcrumb-item.vue'
import C2Breadcrumbs from 'uikit/c2-breadcrumbs/c2-breadcrumbs.vue'
import { computed } from 'vue'

const props = defineProps<BreadcrumbProps>()

const mediaStore = useMediaSizeStore()

const mobileItem = computed(() => {
  if (props.links?.length > 1) {
    return props.links?.[props.links.length - 1]
  } else {
    return props.links[0]
  }
})

function getLabel(index) {
  var label = index === 0 ? 'Home' : props.links?.[index].text
  return 'Back to ' + label
}

function getMobileDefaultLabel(href) {
  return href === '/' ? "Home" : "Back"
}
</script>
