import { defineStore } from 'pinia'
import * as podcastTypes from './monthly-study-podcast-types'

const LOCAL_STORAGE_CONCURRENCY_KEY = 'Audio_Status_Concurrency_Key'
const timeBetweenConcurrencyChecksInMilliseconds = 750
const STORE_ID = 'AUDIO_STATUS'

const defaultState: podcastTypes.IAudioPlayerStatus = {
  audioState: {} as Record<string, podcastTypes.ICurrentAudioState | undefined>,
  lastConcurrencyCheck: 0,
}

export const useAudioStatusStore = defineStore(STORE_ID, {
  state: (): podcastTypes.IAudioPlayerStatus => defaultState,
  persist: typeof window !== 'undefined' && {
    debug: false,
    omit: ['lastConcurrencyCheck'],
  },
  actions: {
    getCurrentTime(key: string, streamUrl: string) {
      if (this.audioState[key] && this.audioState[key].streamUrl === streamUrl) {
        return this.audioState[key].currentTime
      }

      return 0
    },
    getCurrentStatus(key: string) {
      return this.audioState[key] ?? { streamUrl: '', currentTime: 0, duration: 0 }
    },
    getConcurrencyKey() {
      if (typeof window === 'undefined' || typeof Storage === 'undefined') {
        return null
      }

      return window.localStorage.getItem(LOCAL_STORAGE_CONCURRENCY_KEY)
    },
    setConcurrencyKey(key: string) {
      if (typeof window === 'undefined' || typeof Storage === 'undefined') {
        return null
      }

      return window.localStorage.setItem(LOCAL_STORAGE_CONCURRENCY_KEY, key)
    },
    rehydrateIfNeeded(key: string) {
      let now = Date.now()
      if (now - this.lastConcurrencyCheck <= timeBetweenConcurrencyChecksInMilliseconds) {
        return
      }

      this.lastConcurrencyCheck = now
      if (this.getConcurrencyKey() === key) {
        return
      }

      // There is a good chance that another tab is open somewhere and has played audio,
      // so rehydrate from local storage so we don't overwrite that tab's playback position
      this.$hydrate()
      this.setConcurrencyKey(key)
    },
    updateState(key: string, streamUrl: string, duration: number, currentTime: number) {
      if (!streamUrl || streamUrl.length == 0) {
        return
      }

      this.rehydrateIfNeeded(key)
      const howCloseToEndBeforeDelete = 3 // # of seconds
      if (duration <= 0 || duration - currentTime <= howCloseToEndBeforeDelete) {
        if (this.audioState[key]) {
          this.audioState[key] = undefined
        }

        return
      }

      if (!this.audioState[key]) {
        this.audioState[key] = {
          streamUrl: streamUrl,
          currentTime: currentTime,
          duration: duration,
        }

        return
      }

      this.audioState[key].streamUrl = streamUrl
      this.audioState[key].currentTime = currentTime
      this.audioState[key].duration = duration
    },
  },
})
