﻿/**
 * Common rendered user on server and client app build
 *
 */
import components from '@TodayInTheWord/components'
import SFooter from '@TodayInTheWord/components/shared/footer/s-footer.vue'
import SHeader from '@TodayInTheWord/components/shared/header/s-header.vue'
import SButton from '@TodayInTheWord/components/shared/s-button.vue'
import SHeroIcon from '@TodayInTheWord/components/shared/s-hero-icon.vue'
import SLink from '@TodayInTheWord/components/shared/s-link.vue'
import SLoading from '@TodayInTheWord/components/shared/s-loading.vue'
import EpiEdit from '@TodayInTheWord/directives/epi-edit'
import RootComponent from '@TodayInTheWord/layouts/root.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useFooterStore } from '@TodayInTheWord/stores/footer/footer'
import { useHeaderStore } from '@TodayInTheWord/stores/header/header-store'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createSSRApp, h } from 'vue'
import router from './router'

export function createApp(model) {
  const app = createSSRApp({
    name: 'app',
    render: () => h(RootComponent),
  })

  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)
  app.use(pinia) // Create the root store

  const epiPageContextStore = useEpiPageContextStore()
  const footerStore = useFooterStore()
  const headerStore = useHeaderStore()

  const head = createHead()
  app.use(head)

  epiPageContextStore.setInitialState(model)
  headerStore.setInitialState(model)
  footerStore.setStore(model)

  app.use(router)

  //app.component('Root', RootComponent);
  app.directive('epi-edit', EpiEdit)
  app.use(components)

  app.component('s-header', SHeader)
  app.component('s-footer', SFooter)
  app.component('s-link', SLink)
  app.component('s-button', SButton)
  app.component('s-hero-icon', SHeroIcon)
  app.component('s-loading', SLoading)

  return { app, router }
}
