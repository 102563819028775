<template>
  <div v-if="model" class="space-y-5 lg:space-y-6">
    <shared-section-heading :heading="model.heading" />

    <div
      class="space-y-5 lg:p-6"
      :class="{
        'border-lighter-grey rounded-xl border': !mediaSizeStore.isMobile,
      }"
    >
      <div class="flex flex-col gap-y-4 lg:grid lg:grid-cols-2 lg:gap-x-6">
        <s-link
          v-for="(day, index) in days"
          :key="index"
          :href="day.url"
          :external-link="true"
          @mouseover="hoveredItemIndex = index"
          @mouseleave="hoveredItemIndex = null"
          @click="onBeforeNavigation"
          class="hover:bg-grey-50 flex items-center space-x-3 rounded-xl transition-all duration-300 lg:col-span-1"
        >
          <div
            class="border-light-blue-100 bg-light-blue-50 flex w-fit flex-col items-center justify-center space-y-2 rounded-xl border px-5 py-2"
          >
            <span class="text-grey-800 text-[10px] font-medium lg:text-xs">{{ day.dayLabel }}</span>
            <span class="text-light-blue-900 w-8 text-center text-2xl font-semibold">{{
              day.day
            }}</span>
          </div>
          <div class="flex h-full w-full flex-col justify-center">
            <span
              class="text-base font-medium lg:text-lg"
              :class="hoveredItemIndex === index ? 'text-cyan-700' : 'text-cyan-900'"
              >{{ day.title }}</span
            >
            <span class="text-grey-700 text-sm font-normal">{{ day.bibleRef }}</span>
          </div>
          <span>
            <arrow-right-icon
              class="text-light-blue-900 mr-3 h-5 w-5 flex-shrink-0 transition-all duration-300"
              :class="hoveredItemIndex === index ? 'opacity-100' : 'opacity-0'"
            />
          </span>
        </s-link>
      </div>
      <div class="flex justify-center space-x-3 lg:justify-end">
        <button
          @click="prevWeek"
          class="rounded-xl p-3 transition"
          :class="
            showingWeekIndex === 0
              ? 'bg-grey-400 pointer-events-none'
              : 'bg-cyan-600 hover:bg-cyan-900'
          "
        >
          <span class="flex items-center justify-center">
            <arrow-left-icon class="h-6 w-6 text-white" />
          </span>
        </button>
        <button
          @click="nextWeek"
          class="rounded-xl p-3 transition"
          :class="
            showingWeekIndex === maxContent
              ? 'bg-grey-400 pointer-events-none'
              : 'bg-cyan-600 hover:bg-cyan-900'
          "
        >
          <span class="flex items-center justify-center">
            <arrow-right-icon class="h-6 w-6 text-white" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'
import SharedSectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size.ts'
import { useMonthlyStudyPodcastStore } from '@TodayInTheWord/stores/monthly-study/monthly-study-podcast-store'
import { onMounted, ref, watch } from 'vue'
import { CalendarSectionProps, CalendarWeeKProps } from '../types/devotional-monthly-study-types'

interface DevotionalCalendarProps {
  model?: CalendarSectionProps
}

const props = defineProps<DevotionalCalendarProps>()
const mediaSizeStore = useMediaSizeStore()
const podcastStore = useMonthlyStudyPodcastStore()

const selectedWeekID = ref<number>(null)
const days = ref(null)
const hoveredItemIndex = ref<number>(null)
const allWeeksWithConent = ref<number[]>([])
const maxContent = ref<number>(0)
let showingWeekIndex = ref<number>()

watch(
  () => selectedWeekID.value,
  () => {
    getCurrentWeek(selectedWeekID.value)
  },
)

function getCurrentWeek(weekId: number) {
  if (props?.model?.weeks) {
    props.model.weeks.forEach((week: CalendarWeeKProps) => {
      //Gets all returned weeks into array
      //to avoid iteration through a week with no content
      if (!allWeeksWithConent.value.includes(week.weekId)) {
        allWeeksWithConent.value.push(week.weekId)
      }
      //sets the current week to be viewed first
      if (week.weekId === weekId) {
        showingWeekIndex = allWeeksWithConent.value.indexOf(week.weekId)

        days.value = week.devotions
      }
    })
    maxContent.value = allWeeksWithConent.value.length - 1
  }
}

function prevWeek() {
  if (showingWeekIndex === 0) {
    return
  } else {
    showingWeekIndex = showingWeekIndex - 1

    selectedWeekID.value = allWeeksWithConent.value[showingWeekIndex]
  }
}

function nextWeek() {
  if (showingWeekIndex === allWeeksWithConent.value.length - 1) {
    return
  } else {
    showingWeekIndex.value = showingWeekIndex + 1

    selectedWeekID.value = allWeeksWithConent.value[showingWeekIndex]
  }
}

function onBeforeNavigation(event: Event) {
  podcastStore.pause()
}

onMounted(() => {
  selectedWeekID.value = props.model.selectedWeekId
  getCurrentWeek(props.model.selectedWeekId)
})
</script>

<style scoped></style>
