<template>
  <div v-if="model">
    <div
      v-if="model"
      class="border-b-lighter-grey flex w-full flex-col-reverse items-center gap-y-4 border-b pb-9 lg:flex-row lg:gap-x-12 lg:pb-12"
    >
      <div class="flex w-full flex-col">
        <div
          class="row item-center mb-3 flex justify-between lg:mb-4 lg:justify-normal lg:space-x-4"
        >
          <tag
            v-if="model.tag"
            :tag="{ displayName: model.tag, tagColor: 'light-blue' }"
            class="rounded-xl px-3 py-1"
          />
          <div v-if="model.date" class="flex items-center">
            {{ model.date }}
          </div>
        </div>
        <div class="mb-3 space-y-1 lg:mb-10 lg:space-y-2">
          <h3 v-if="model.title" class="font-semibold text-cyan-900 lg:text-4xl">
            {{ model.title }}
          </h3>
          <div
            v-if="model.description"
            class="text-grey-700 text-base font-normal leading-[30px] lg:text-xl lg:leading-7"
          >
            {{ model.description }}
          </div>
        </div>
        <div class="flex space-x-2.5">
          <s-link
            v-if="model.monthlyStudyLink && model.monthlyStudyLink.href"
            :href="model.monthlyStudyLink.href"
            :external-link="model.monthlyStudyLink.isExternal"
            :target="model.monthlyStudyLink.target"
            :emphasis="'bold'"
            :style="'primary'"
            class="w-fit"
          >
            {{ model.monthlyStudyLink.text }}
          </s-link>

          <s-link
            v-if="model.devotionalDetailLink && model.devotionalDetailLink.href"
            :href="model.devotionalDetailLink.href"
            :external-link="model.devotionalDetailLink.isExternal"
            :target="model.devotionalDetailLink.target"
            :emphasis="'bold'"
            :style="'secondary'"
            class="w-fit"
          >
            {{ model.devotionalDetailLink.text }}
          </s-link>
        </div>
      </div>
      <div
        class="lg:w-7/12 lg:shrink-0 lg:overflow-hidden lg:rounded-xl"
        :class="{ '-mx-[18px]': mediaSizeStore.isMobile }"
      >
        <s-image
          :model="model.image"
          class="aspect-video h-full w-full object-cover"
          :preload="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import sImage from '@TodayInTheWord/components/shared/s-image.vue'
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import tag from '@TodayInTheWord/components/shared/tag.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { HeaderComponentProps } from '../../types/devotioal-index-types'

interface DevotionalHeaderProps {
  model?: HeaderComponentProps
}

const mediaSizeStore = useMediaSizeStore()

const props = defineProps<DevotionalHeaderProps>()
</script>

<style scoped></style>
