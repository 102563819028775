<template>
  <div
    class="TITW video-detail mx-auto mt-5 w-full space-y-8 md:max-w-[856px]"
    :class="{ container: mediaSizeStore.isMobile }"
  >
    <bread-crumbs
      class="!mx-0 mb-4 !px-0 md:mb-6"
      :links="breadcrumbs.links"
      :currentPageName="breadcrumbs.currentPageName"
    />
    <section
      v-if="model && (model.sectionHeading || model.sectionSubheading)"
      v-bind:id="model.clientID"
    >
      <div v-if="model.sectionHeading || model.sectionSubheading" class="mb-5 lg:mb-6">
        <section-heading
          :heading="model.sectionHeading"
          :subheading="model.sectionSubheading"
          :sub-heading-classes="'text-grey-700'"
        />
      </div>
    </section>
  </div>
  <div class="video-wrapper !my-0 mx-auto w-full md:max-w-[856px]">
    <div
      class="relative mb-0 inline-flex w-full flex-col items-start justify-start overflow-hidden bg-slate-700 md:mx-0 md:rounded-xl"
    >
      <picture v-if="model.image" class="absolute flex aspect-[16/9]">
        <shared-image
          :model="model.featuredImage"
          class="h-full w-full object-cover"
          format="?width=856&height=491&format=png&oldformat=webp"
        />
      </picture>
      <div
        class="aspect-[16/9] h-full w-full object-cover"
        v-if="model.videoEmbedCode"
        v-html="model.videoEmbedCode"
      ></div>
      <template v-if="model.videoShareScript">
        <div class="h-full w-full object-cover" id="featured-custom-video-script"></div>
      </template>
    </div>
  </div>
  <div
    class="mx-auto !mt-4 mt-5 w-full space-y-8 md:max-w-[856px]"
    :class="{ container: mediaSizeStore.isMobile }"
  >
    <div class="info flex flex-col gap-1">
      <div v-if="model.categories || model.displayDate" class="flex justify-between">
        <div
          v-if="model.categories && model.categories.length > 0"
          class="bullet-seperator flex items-center space-x-1 [&>:not(:first-child)]:before:mr-1"
        >
          <span
            v-for="(category, index) in computedCategories"
            :key="index"
            class="text-grey-500 text-xs font-normal"
          >
            {{ category }}
          </span>
        </div>
        <div v-if="model.displayDate" class="text-xs font-medium text-gray-500">
          {{ model.displayDate }}
        </div>
      </div>
      <h1 class="text-light-blue-900 text-lg font-semibold leading-7">
        {{ model.title }}
      </h1>
      <div class="text-grey-800 text-sm font-normal leading-tight">
        {{ model.summary }}
      </div>

      <div class="relative mt-4 flex space-x-1">
        <share-this />
      </div>
    </div>
    <RelatedContent :model="model.relatedMonthlyStudyPage" showTag="false" showCategories="true" />
    <div class="border-grey-200 mb-8 mt-4 border md:my-10"></div>
    <div v-if="state.transcriptVisible" class="transcript mb-8 flex flex-col md:mb-12">
      <div
        class="bg-grey-50 border-grey-100 inline-flex flex-col items-start justify-start gap-4 rounded-xl border p-6 text-gray-800"
      >
        <h3 v-if="model.transcript.heading" class="text-base font-medium leading-normal">
          {{ model.transcript.heading }}
        </h3>
        <div
          v-if="model.transcript.text"
          class="transition-max-height duration-1500 self-stretch overflow-hidden whitespace-pre-line pb-4 text-sm font-normal leading-tight"
          :class="{ 'max-h-[260px]': !state.isOpen, 'max-h-none': state.isOpen }"
        >
          <div ref="transcriptElement">
            {{ model.transcript.text }}
          </div>
        </div>

        <div
          class="group inline-flex items-center justify-center gap-2 rounded-xl p-1 text-center text-sm leading-tight text-cyan-700 transition duration-300 ease-in-out"
          :class="{ hidden: state.hideControls }"
          @click="updateOpenStatus()"
        >
          <template v-if="!state.isOpen">
            <div class="cursor-pointer group-hover:text-cyan-500">
              Show {{ model.transcript.heading }}
            </div>

            <div class="relative h-4 w-4 cursor-pointer group-hover:text-cyan-500">
              <ArrowDownIcon class="w-full" />
            </div>
          </template>
          <template v-else>
            <div class="cursor-pointer group-hover:text-cyan-500">
              Hide {{ model.transcript.heading }}
            </div>

            <div class="relative h-4 w-4 cursor-pointer group-hover:text-cyan-500">
              <ArrowUpIcon class="w-full" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <RelatedContent :model="model.relatedContent" showTag="false" showCategories="true" />
  </div>
</template>

<script setup lang="ts">
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/24/outline'
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import AboutTheAuthor from '@TodayInTheWord/components/pages/shared/about-the-author.vue'
import RelatedContent from '@TodayInTheWord/components/pages/shared/related-content.vue'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import ShareThis from '@TodayInTheWord/components/shared/share-this/s-share-this-button.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { DateTime } from 'luxon'
import { computed, nextTick, onMounted, onUpdated, ref, Ref } from 'vue'
import { VideoDetailProps } from './types/video-detail-types'

const model: VideoDetailProps = useEpiPageContextStore().model
const breadcrumbs: BreadcrumbProps = useEpiPageContextStore().breadcrumbs
const mediaSizeStore = useMediaSizeStore()
const transcriptElement: Ref<HTMLDivElement | null> = ref(null)

onMounted(() => {
  createScript()
  watchElementHeight()
  showTranscript()
})

onUpdated(() => {
  watchElementHeight()
})

const state = ref({
  isOpen: false,
  hideControls: false,
  transcriptVisible: true,
})

const watchElementHeight = () => {
  if (transcriptElement.value) {
    const height = transcriptElement.value.scrollHeight
    state.value.hideControls = height < 500
  }
}

const createScript = () => {
  //clear any previous scripts
  var prevScript = document.querySelector('#featured-custom-video-script')
  if (prevScript !== null) {
    prevScript.innerHTML = ''
    var script = document.createElement('script')
    prevScript.appendChild(script)
    if (model?.videoShareScript) {
      script.innerHTML = model?.videoShareScript
    }
  }
}

const computedCategories = computed(() => {
  return model?.categories?.slice(0, 2)
})
function updateOpenStatus() {
  state.value.isOpen = !state.value.isOpen

  nextTick(() => {
    if (!state.value.isOpen && transcriptElement.value) {
      transcriptElement.value.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      })
    } else if (!state.value.isOpen) {
      window.scrollTo({ top: 0, behavior: 'instant' })
    }
  })
}

function showTranscript() {
  //checks transcript object for properties
  if (Object.prototype.hasOwnProperty.call(model.transcript, 'text')) {
    state.value.transcriptVisible = true
  } else {
    state.value.transcriptVisible = false
  }
}
</script>
<style lang="scss">
.video-wrapper {
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
