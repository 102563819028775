<template>
  <div
    v-if="podcasts && podcasts.length > 1"
    class="border-grey-100 bg-grey-50 overflow-y-scroll rounded-xl border px-3 lg:max-h-[424px] lg:px-4"
  >
    <PodcastListItem
      v-for="(podcast, index) in podcasts"
      :key="podcast.streamUrl"
      class="border-b-grey-200 border-b last:bg-none"
      :podcast-data="podcast"
    />
  </div>
</template>

<script setup lang="ts">
import { useMonthlyStudyPodcastStore } from '@TodayInTheWord/stores/monthly-study/monthly-study-podcast-store'
import { storeToRefs } from 'pinia'
import PodcastListItem from './podcast-list-item.vue'

const podcastStore = useMonthlyStudyPodcastStore()
const { podcasts } = storeToRefs(podcastStore)
</script>
