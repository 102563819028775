<template>
  <div v-if="model" class="container mx-auto" v-bind:id="model.clientID">
    <div class="flex w-full flex-col lg:flex-row lg:gap-x-6">
      <div class="mb-5 flex w-full flex-col space-y-3 lg:mb-0 lg:w-1/2 lg:space-y-4">
        <div
          v-if="model.video && (model.video.videoEmbedCode || model.video.videoShareScript)"
          class="relative aspect-video w-full overflow-hidden rounded-xl"
        >
          <s-image
            :model="model.video.image"
            class="w-full object-cover"
            format="?width=636&height=358&format=png&oldformat=webp&compand=true"
          />
          <button
            ref="playButton"
            class="group absolute inset-0 m-auto h-fit w-fit p-4"
            @click="openVideoModal"
          >
            <play-icon
              class="fill-lighter-grey group-hover:fill-light-blue-600 h-10 w-10 transition-all duration-300"
            />
            <span class="sr-only">Play Video</span>
          </button>
        </div>
        <div v-if="model.fromTheAuthor" class="space-y-1 lg:space-y-2">
          <div v-if="model.fromTheAuthor.smallIntro" class="text-grey-700 text-xs font-medium">
            {{ model.fromTheAuthor.smallIntro }}
          </div>
          <div
            v-if="model.fromTheAuthor.headline"
            class="text-base font-semibold text-cyan-900 lg:text-lg"
          >
            {{ model.fromTheAuthor.headline }}
          </div>
          <div
            v-if="model.fromTheAuthor.authorSummary"
            class="text-grey-700 line-clamp-2 text-sm font-normal lg:text-base"
          >
            {{ model.fromTheAuthor.authorSummary }}
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/2">
        <s-link
          v-if="model.monthlyStudyCard && model.monthlyStudyCard.url"
          :href="model.monthlyStudyCard.url"
          class="border-lighter-grey group flex flex-col items-center space-y-4 border-b pb-5 lg:flex-row lg:space-y-0 lg:pb-7"
        >
          <div
            v-if="model.monthlyStudyCard.image"
            class="aspect-video w-full overflow-hidden rounded-xl transition-all duration-300 ease-in-out group-hover:shadow-lg lg:w-1/2"
          >
            <s-image
              :model="model.monthlyStudyCard.image"
              class="w-full object-cover"
              format="?width=604&height=341&format=png&oldformat=webp&compand=true"
            >
              <span class="sr-only">Link to Monthly Study page</span>
            </s-image>
          </div>
          <div class="w-full lg:w-1/2 lg:py-3 lg:pl-6">
            <div
              v-if="model.monthlyStudyCard.title || model.monthlyStudyCard.description"
              class="mb-4 space-y-1"
            >
              <div
                v-if="model.monthlyStudyCard.title"
                class="text-base font-semibold text-cyan-900 transition-all duration-300 ease-in-out group-hover:text-cyan-700 lg:text-lg"
              >
                {{ model.monthlyStudyCard.title }}
              </div>
              <div
                v-if="model.monthlyStudyCard.description"
                class="text-grey-700 text-sm font-normal lg:text-base"
              >
                {{ model.monthlyStudyCard.description }}
              </div>
            </div>
            <div
              v-if="model.monthlyStudyCard.author || model.monthlyStudyCard.tag"
              class="flex justify-between"
            >
              <div v-if="model.monthlyStudyCard.author" class="text-grey-800 text-sm font-medium">
                {{ model.monthlyStudyCard.author }}
              </div>
              <tag
                v-if="model.monthlyStudyCard.tag"
                :tag="{ displayName: model.monthlyStudyCard.tag, tagColor: 'light-blue' }"
                class="rounded-xl px-2 py-0.5 text-xs"
              />
            </div>
          </div>
        </s-link>
        <div v-if="model.relatedArticles" class="flex flex-col lg:grid lg:grid-cols-2 lg:gap-x-6">
          <s-link
            v-for="(card, index) in model.relatedArticles"
            :href="card.link.href"
            :key="index"
            class="group block w-full py-6 transition-all duration-300 lg:col-span-1 lg:py-5"
            :class="{ 'border-lighter-grey border-b': index === 0 || index === 1 }"
          >
            <tag
              v-if="card.type"
              :tag="card.type"
              class="mb-3 w-fit rounded-xl px-2 py-0.5 text-xs lg:mb-2"
            />
            <div class="space-y-1">
              <div
                v-if="card.title"
                class="text-sm font-medium text-cyan-900 group-hover:text-cyan-700 lg:text-base"
              >
                {{ card.title }}
              </div>
              <div v-if="card.description" class="text-grey-700 line-clamp-2 text-sm font-normal">
                {{ card.description }}
              </div>
            </div>
          </s-link>
        </div>
      </div>
    </div>
    <c2-modal
      v-if="model.video && (model.video.videoEmbedCode || model.video.videoShareScript)"
      ref="videoModal"
      :background-color="'transparent'"
      :background-overlay-color="'rgba(0,0,0,0.8)'"
      :close-btn-classes="'flex items-center justify-center h-12 w-12 absolute top-0 right-0'"
      :header-classes="'flex items-end justify-between min-h-[48px] px-4 relative'"
      :wrapper-classes="'bg-white p-4 max-h-screen'"
      class="relative h-0 overflow-hidden"
      @modal-opened="toggleShowVideo(true)"
      @modal-closed="toggleShowVideo(false)"
    >
      >
      <template v-slot:close-btn>
        <span class="sr-only">Close Modal</span>
        <span class="h-6 w-6"><XMarkIcon class="text-white" /></span>
      </template>

      <template v-slot:body>
        <div
          v-if="model.video && model.video.videoEmbedCode"
          class="iframe-body relative aspect-[16/9] w-full overflow-hidden rounded-xl bg-white"
          v-html="model.video.videoEmbedCode"
        ></div>
      </template>
    </c2-modal>
    <template v-if="model.video && model.video.videoShareScript">
      <div id="featured-custom-video-script"></div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { PlayIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import sImage from '@TodayInTheWord/components/shared/s-image.vue'
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import tag from '@TodayInTheWord/components/shared/tag.vue'
import C2Modal from 'uikit/c2-modal/c2-modal.vue'
import { computed, ref } from 'vue'
import { StudyContentProps } from './types/study-content'

const props = defineProps<StudyContentProps>()

const videoModal = ref(null)
const showVideoSrc = ref(true)
const playButton = ref<HTMLElement | null>()

function openVideoModal() {
  videoModal.value.openModal()
}

function closevideoModal() {
  videoModal.value.closeModal()
}

const toggleShowVideo = (bool: boolean) => {
  showVideoSrc.value = bool

  if (showVideoSrc.value) {
    createScript()
  } else {
    pauseAndReturnFocus()
  }
}
const createScript = () => {
  //clear any previous scripts
  var prevScript = document.querySelector('#featured-custom-video-script')
  if (prevScript !== null) {
    prevScript.innerHTML = ''
    var script = document.createElement('script')
    prevScript.appendChild(script)
    if (props.model?.video.videoShareScript) {
      script.innerHTML = props.model?.video.videoShareScript
    }
  }
}
const pauseAndReturnFocus = () => {
  const videos = [...document.getElementsByTagName('video')]
  videos.forEach((vid) => {
    vid.pause()
  })
  const iframeVideo = videoModal.value.$el.querySelector('iframe')
  if (iframeVideo) {
    let src = iframeVideo.src
    iframeVideo.src = src
  }

  playButton.value.focus()
}
</script>

<style lang="scss">
.iframe-body {
  iframe {
    position: relative;
    height: 100%;
    width: 100%;
  }
}
</style>
