<template>
  <button
    @click="podcastStore.seekBack(jumpBackTime)"
    class="bg-light-blue-600 hover:bg-light-blue-900 rounded-xl p-2 transition"
  >
    <span class="flex items-center justify-center">
      <RewindSvg class="h-6 w-6 text-white" />
    </span>
  </button>
</template>

<script setup lang="ts">
import { isDefined } from '@shared/api/type-utilities'
import RewindSvg from '@TodayInTheWord/components/icons/rewind-new-svg.vue'
import { useMonthlyStudyPodcastStore } from '@TodayInTheWord/stores/monthly-study/monthly-study-podcast-store'
import { computed } from 'vue'

interface JumpBackButtonProps {
  TimeInSeconds?: number
}

const props = defineProps<JumpBackButtonProps>()

const podcastStore = useMonthlyStudyPodcastStore()

const defaultSeekTime = 15

const jumpBackTime = computed(() => {
  return isDefined(props.TimeInSeconds) && props.TimeInSeconds > 0
    ? props.TimeInSeconds
    : defaultSeekTime
})
</script>
