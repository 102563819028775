﻿<script>
import C2Slider from 'uikit/c2-slider/c2-slider.vue';
C2Slider.methods.calcSlideTranslateWidth = function (direction) {
  const totalSlides = Math.round(this.calcSlideSliderWidth() / this.fullWidth);
  const slideSetWidth = this.fullWidth / totalSlides;
  const slideSetRemainingWidth = (totalSlides % 1) * slideSetWidth;

  // hides nav if only one slide
  this.checkForNavigation();

  if (totalSlides % 1 === 0) {
    return slideSetWidth;
  }

  // returns different number if there is remaining space
  if (direction === 'prev' && this.currentSlide + 1 > totalSlides) {
    return slideSetRemainingWidth;
  } else if (direction === 'next' && this.currentSlide > totalSlides) {
    return slideSetRemainingWidth;
  }

  return slideSetWidth;
}
export default C2Slider
</script>