import { getDateTime } from '@shared/api/date-time'
import { isDefined } from '@shared/api/type-utilities'
import * as webApi from '@shared/api/web-api'
import { formatAudioTime } from '@TodayInTheWord/api/audio-time-formatter'
import {
  PodcastProps,
  PodcastsSectionApiProps,
} from '@TodayInTheWord/components/pages/devotional-monthly-study/types/devotional-monthly-study-types'
import { useAudioStatusStore } from '@TodayInTheWord/stores/monthly-study/audio-player-status-store'
import { defineStore } from 'pinia'
import { nextTick } from 'vue'
import * as podcastTypes from './monthly-study-podcast-types'

const STORE_ID = 'MONTHLY_STUDY_PODCASTS'

const defaultState: podcastTypes.IMonthlyStudyPodcastModel = {
  isLoadingPodcastList: false,
  isPlayRequested: false,
  podcasts: [],
  streamUrl: null,
  activePodcast: null,
  currentTime: null,
  isPlaying: false,
  audio: null,
  podcastListDataUrl: null,
  PodcastListAbortController: null,
  currentSortDate: null,
  isCurrentMetadataLoaded: false,
}

export const useMonthlyStudyPodcastStore = defineStore(STORE_ID, {
  state: (): podcastTypes.IMonthlyStudyPodcastModel => defaultState,
  persist: typeof window !== 'undefined' && {
    debug: false,
    omit: ['audio', 'PodcastListAbortController'],
  },
  getters: {
    showList(): boolean {
      if (this.podcasts?.length > 1) {
        return true
      }

      return false
    },
    currentDuration(): number | undefined {
      return this.activePodcast?.duration
    },
    formattedCurrentTime(): string {
      return formatAudioTime(this.currentTime)
    },
    formattedCurrentDuration(): string {
      return formatAudioTime(this.currentDuration)
    },
  },
  actions: {
    async loadPodcastListAsync(url?: string) {
      if (!url) {
        this.podcasts.length = 0
        return
      }

      this.isLoadingPodcastList = true
      this.podcastListDataUrl = url
      this.podcasts.length = 0

      const audioStatusStore = useAudioStatusStore()

      let currentDate = getDateTime()
      this.currentSortDate = currentDate.toFormat('yyyy-MM-dd')

      this.PodcastListAbortController = new AbortController()
      const podcastApiResponse = await webApi.callAsync<PodcastsSectionApiProps>({
        method: 'get',
        url: url,
        signal: this.PodcastListAbortController.signal,
      })

      this.PodcastListAbortController = null
      const responseItems = podcastApiResponse.data?.items ?? []
      const length = responseItems.length
      let todaysPodcast: PodcastProps | undefined
      let currentlyStreamingPodcast: PodcastProps | undefined
      const currentState = audioStatusStore.getCurrentStatus(url)
      for (let i = 0; i < length; i++) {
        const item = responseItems[i]
        item.dataUrl = url
        if (!currentlyStreamingPodcast && item.streamUrl === currentState.streamUrl) {
          if (currentState.currentTime > 0) {
            item.currentTime = currentState.currentTime
          }

          currentlyStreamingPodcast = item
        }

        if (!todaysPodcast && item.sortDate && item.sortDate === this.currentSortDate) {
          todaysPodcast = item
        }

        this.podcasts.push(item)
      }

      this.isLoadingPodcastList = false

      if (currentlyStreamingPodcast?.streamUrl) {
        this.loadPodcast(currentlyStreamingPodcast)
        return
      }

      if (!todaysPodcast && this.podcasts && this.podcasts.length > 0) {
        todaysPodcast = this.podcasts[0]
      }

      if (todaysPodcast) {
        this.loadPodcast(todaysPodcast)
      }
    },
    seekForward(seekTimeInSeconds: number) {
      if (seekTimeInSeconds === 0) {
        return
      }

      if (!this.audio) {
        return
      }

      this.audio.currentTime += seekTimeInSeconds
    },
    seekBack(seekTimeInSeconds: number) {
      if (seekTimeInSeconds === 0) {
        return
      }

      if (!this.audio) {
        return
      }

      this.audio.currentTime -= seekTimeInSeconds
    },
    seek(seekTimeInSeconds: number) {
      if (seekTimeInSeconds === 0) {
        return
      }

      if (!this.audio) {
        return
      }

      this.audio.currentTime = seekTimeInSeconds
    },
    loadPodcast(podcast: PodcastProps | null) {
      if (!podcast?.streamUrl) {
        return
      }

      if (this.audio && this.audio.src) {
        this.audio.pause()
        this.audio.src = ''
        this.audio = null
        this.isCurrentMetadataLoaded = false
      }

      if (!podcast.streamUrl) {
        console.warn(`Missing podcast URL for ${podcast.date} :: ${podcast.title}`)
        return
      }

      this.activePodcast = podcast
      this.streamUrl = podcast.streamUrl

      const audioPlayer = new Audio()
      const dataUrl = podcast.dataUrl ?? ''
      audioPlayer.setAttribute('data-podcast-url', dataUrl)

      if (this.isPlayRequested) {
        audioPlayer.preload = 'auto'
        audioPlayer.oncanplay = (event) => {
          console.log('onCanPlay')
          if (!this.isPlayRequested) {
            return
          }

          this.isPlayRequested = false
          this.playAsync()
        }
      } else {
        audioPlayer.preload = 'metadata'
      }

      audioPlayer.onloadedmetadata = (event) => {
        if (!this.activePodcast) {
          return
        }

        const audioTarget = event.currentTarget as HTMLAudioElement
        if (audioTarget?.duration) {
          this.activePodcast.duration = audioTarget.duration
          let dataUrl = audioTarget.getAttribute('data-podcast-url') ?? audioTarget.src
          const audioStatusStore = useAudioStatusStore()
          audioTarget.currentTime = audioStatusStore.getCurrentTime(dataUrl, audioTarget.src)
          this.activePodcast.currentTime = audioTarget.currentTime
          this.currentTime = audioTarget.currentTime
          this.isCurrentMetadataLoaded = true
        }
      }

      audioPlayer.ontimeupdate = (event) => {
        const audioTarget = event.currentTarget as HTMLAudioElement
        if (!audioTarget) {
          return
        }

        this.isPlaying = !audioTarget.paused
        this.currentTime = audioTarget.currentTime
        if (isDefined(this.activePodcast)) {
          this.activePodcast.currentTime = audioTarget.currentTime
        }

        if (audioTarget.currentTime === audioTarget.duration) {
          audioTarget.pause()
        }

        let dataUrl = audioTarget.getAttribute('data-podcast-url') ?? audioTarget.src
        const audioStateStore = useAudioStatusStore()
        audioStateStore.updateState(
          dataUrl,
          audioTarget.src,
          audioTarget.duration,
          audioTarget.currentTime,
        )
      }

      audioPlayer.src = podcast.streamUrl
      this.audio = audioPlayer
    },
    isPodcastNotAlreadyPlaying(podcast?: PodcastProps): boolean {
      if (!podcast || !podcast.streamUrl) {
        return false
      }

      if (this.streamUrl === podcast.streamUrl) {
        return false
      }

      return true
    },
    async playAsync() {
      if (this.audio?.src) {
        this.isPlaying = true
        await this.audio.play()
      }
    },
    playOrPause(podcast?: PodcastProps | null) {
      if (podcast && podcast !== null && this.isPodcastNotAlreadyPlaying(podcast)) {
        // We are switching to a new podcast, so start it at the beginning
        podcast.currentTime = 0
        this.isPlayRequested = true
        this.loadPodcast(podcast)
        return
      }

      if (this.audio?.paused) {
        if (this.audio.currentTime == this.audio.duration) {
          this.audio.currentTime = 0
          this.currentTime = 0
          if (isDefined(this.activePodcast)) {
            this.activePodcast.currentTime = 0
          }
        }

        this.playAsync()
        // nextTick(() => {
        //   this.playAsync()
        // })
      } else {
        this.audio?.pause()
        this.isPlaying = false
      }
    },
    pause() {
      this.audio?.pause()
      this.isPlaying = false
    },
    resetState() {
      if (isDefined(this.audio)) {
        if (!this.audio.paused) {
          this.audio.pause()
        }

        this.audio = null
      }

      this.activePodcast = null
      this.streamUrl = null
      this.currentTime = null
      this.isPlaying = false
      if (isDefined(this.PodcastListAbortController)) {
        this.PodcastListAbortController.abort()
        this.PodcastListAbortController = null
      }
    },
  },
})
