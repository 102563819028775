<template>
  <div
    class="podcast-player bg-grey-50 border-grey-100 flex w-full flex-col space-y-3 rounded-xl border p-3 lg:p-4"
  >
    <div class="flex justify-between">
      <div
        v-if="activePodcast?.title"
        class="title text-light-blue-900 text-base font-medium leading-6"
      >
        {{ activePodcast?.title }}
      </div>
      <div class="timer text-grey-700 text-base font-normal leading-6">
        {{ timeDisplay }}
      </div>
    </div>
    <div
      class="flex flex-row flex-wrap items-center justify-between lg:flex-nowrap lg:justify-normal"
    >
      <div class="order-2 lg:order-none">
        <podcast-play-pause-button />
      </div>

      <div class="order-1 mb-3 flex w-full lg:order-none lg:mb-0">
        <podcast-player-audio-slider />
      </div>
      <div class="order-3 ml-3 flex space-x-2 lg:order-none">
        <podcast-player-jump-back-button />
        <podcast-player-jump-forward-button />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMonthlyStudyPodcastStore } from '@TodayInTheWord/stores/monthly-study/monthly-study-podcast-store'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import PodcastPlayPauseButton from './podcast-play-pause-button.vue'
import podcastPlayerAudioSlider from './podcast-player-audio-slider.vue'
import PodcastPlayerJumpBackButton from './podcast-player-jump-back-button.vue'
import PodcastPlayerJumpForwardButton from './podcast-player-jump-forward-button.vue'

const podcastStore = useMonthlyStudyPodcastStore()
const {
  currentTime,
  currentDuration,
  activePodcast,
  formattedCurrentTime,
  formattedCurrentDuration,
} = storeToRefs(podcastStore)

const isMounted = ref(false)

const sliderTime = ref(0)
watch(currentTime, (value) => {
  if (isMounted.value === false) {
    return
  }

  sliderTime.value = value ?? 0
})

const sliderDuration = ref(1)
watch(currentDuration, (value) => {
  if (isMounted.value === false) {
    return
  }

  sliderDuration.value = value ?? 1
})

const timeDisplay = computed(() => {
  const currentTimeString = formattedCurrentTime.value
  if (!formattedCurrentTime.value && !formattedCurrentDuration.value) {
    return ''
  }

  if (!formattedCurrentTime.value) {
    return formattedCurrentDuration.value
  }

  if (!formattedCurrentDuration.value) {
    return formattedCurrentTime.value
  }

  return `${currentTimeString} / ${formattedCurrentDuration.value}`
})

onMounted(() => {
  isMounted.value = true
  sliderDuration.value = currentDuration.value ?? 1
  sliderTime.value = currentTime.value ?? 0
})
</script>

<style lang="scss">
.podcast-player {
  .audio-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 6px;
    background: #bae6fd;
  }

  .audio-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    background-color: #0369a1;
    border-radius: 50%;
    border: none;
  }

  .audio-slider::-moz-range-thumb {
    height: 20px;
    width: 20px;
    background-color: #0369a1;
    border-radius: 50%;
    border: none;
  }
}
</style>
