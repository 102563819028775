<template>
  <s-link
    v-if="model.url"
    :class="class"
    :href="model.url"
    :external-link="isExternal"
    @click.capture="$emit('trackHit', query, model.hitId, trackId)"
  >
    <div
      class="bg-light-blue-100 relative aspect-[16/9] w-full flex-shrink-0 overflow-hidden rounded-xl transition-all duration-300 ease-in-out group-hover:shadow-lg"
    >
      <picture v-if="model.image" class="flex">
        <img
          :src="model.image + '?width=264&height=176&format=png&oldformat=webp&compand=true'"
          :alt="model.title"
          class="aspect-[16/9] h-full w-full overflow-hidden rounded-xl"
        />
      </picture>
      <div
        v-if="isDateWithinOneYear(model.displayDate)"
        class="absolute bottom-2 left-2 w-fit rounded-lg bg-black/60 px-2 py-1 text-xs text-white"
      >
        {{ model.displayDate }}
      </div>
    </div>
    <div class="flex h-full flex-col p-2">
      <div class="flex h-full flex-col">
        <span
          v-if="model.title"
          class="text-grey-700 group-hover:text-light-blue-600 mb-1 font-medium"
        >
          {{ model.title }}
        </span>
        <div
          v-if="model.description"
          class="text-grey-700 group-hover:text-light-blue-600 mb-3 line-clamp-4 text-sm"
        >
          {{ model.description }}
        </div>
        <div
          v-if="model.authorNames && model.authorNames.length > 0"
          class="text-grey-700 text-justify text-xs font-medium leading-none"
        >
          {{ computedAuthor }}
        </div>
      </div>
    </div>
  </s-link>
</template>

<script setup lang="ts">
import SearchHitTracking from '@shared/api/search-hit-tracking'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import SLink from '@TodayInTheWord/components/shared/s-link.vue'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { isDateWithinOneYear } from '@TodayInTheWord/scripts/date-util'
import { useSearchPageStore } from '@TodayInTheWord/stores/search-page/search-store'
import * as searchTypes from '@TodayInTheWord/stores/search-page/search-types'
import { computed } from 'vue'

const searchStore = useSearchPageStore()
const trackId = computed(() => {
  return searchStore?.searchResults?.trackId
})

const query = computed(() => {
  return searchStore?.searchResults?.query
})

const items = computed((): searchTypes.TitwSearchResultItemModel[] | null => {
  return searchStore?.searchResults?.results?.pages?.items ?? null
})

const isExternal = computed(() => {
  return props.model?.url && props.model.url.startsWith('http')
})

const computedAuthor = computed(() => {
  //return the first author
  return props?.model?.authorNames[0]
})

const emit = defineEmits(['changeTab', 'trackHit'])

const props = defineProps({
  model: {
    type: Object,
    default: () => {},
  },
  hasContainer: {
    type: Boolean,
    default: null,
  },
  class: {
    type: String,
    default:
      'group relative flex grow flex-col justify-between rounded-xl mx-auto mb-4 w-full px-3 md:w-1/3',
  },
})

const contentTypeTag = computed(() => {
  if (!props.model.contentType) {
    return null
  }

  return {
    displayName: props.model.contentType,
    tagColor: 'dark-blue',
  }
})
</script>

<style lang="scss"></style>
