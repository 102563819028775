<template>
  <div v-if="model">
    <div
      v-if="model"
      class="lg:border-b-lighter-grey flex w-full flex-col-reverse items-center gap-y-4 lg:flex-row lg:gap-x-12 lg:border-b lg:pb-12"
    >
      <div class="w-full" :class="{ container: mediaSizeStore.isMobile }">
        <div
          class="border-b-lighter-grey flex w-full flex-col"
          :class="{ 'border-b-lighter-grey border-b pb-9': mediaSizeStore.isMobile }"
        >
          <div
            class="row item-center mb-3 flex justify-between lg:mb-4 lg:justify-normal lg:space-x-4"
          >
            <tag
              v-if="model.tag"
              :tag="{ displayName: model.tag, tagColor: 'light-blue' }"
              class="rounded-xl px-3 py-1 text-sm font-medium"
            />
            <div v-if="model.date" class="flex items-center">
              {{ model.date }}
            </div>
          </div>
          <div class="mb-3 space-y-1 lg:mb-10 lg:space-y-2">
            <h3 v-if="model.title" class="text-2xl font-semibold text-cyan-900 lg:text-4xl">
              {{ model.title }}
            </h3>
            <div v-if="model.description" class="text-grey-700 text-base font-normal lg:text-xl">
              {{ model.description }}
            </div>
          </div>
          <div class="flex space-x-2.5">
            <s-link
              v-if="model.viewMaterialsLink && model.viewMaterialsLink.href"
              :href="model.viewMaterialsLink.href"
              :external-link="model.viewMaterialsLink.isExternal"
              :ariaLabel="model.viewMaterialsLinkAriaLabel"
              :emphasis="'bold'"
              :style="'primary'"
              class="w-fit"
            >
              {{ model.viewMaterialsLink.text }}
            </s-link>
          </div>
        </div>
      </div>
      <div
        class="aspect-video w-full lg:w-7/12 lg:shrink-0 lg:overflow-hidden lg:rounded-xl"
        :class="{ '-mx-[18px]': mediaSizeStore.isMobile }"
      >
        <s-image :model="model.image" class="h-full w-full object-cover" preload="true" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { HeaderComponentProps } from '@TodayInTheWord/components/pages/discussion-starter-index/types/discussion-starters-types'
import sImage from '@TodayInTheWord/components/shared/s-image.vue'
import sLink from '@TodayInTheWord/components/shared/s-link.vue'
import tag from '@TodayInTheWord/components/shared/tag.vue'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'

interface DiscussionStarterHeaderProps {
  model?: HeaderComponentProps
}

const mediaSizeStore = useMediaSizeStore()

const props = defineProps<DiscussionStarterHeaderProps>()
</script>

<style scoped></style>
