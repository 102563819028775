<template>
  <div v-if="props.model && props.model?.dataUrl" class="space-y-3">
    <skeleton-loader v-if="isLoadingPodcastList || !isMounted">
      <SearchPageSkeleton />
    </skeleton-loader>
    <template v-else>
      <shared-section-heading :heading="props.model.heading" />
      <podcast-player />
      <podcast-list
        class="border-grey-100 bg-grey-50 overflow-y-scroll rounded-xl border px-3 lg:max-h-[424px] lg:px-4"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import SharedSectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import SearchPageSkeleton from '@TodayInTheWord/components/icons/forward-new-svg.vue'
import SkeletonLoader from '@TodayInTheWord/components/shared/skeleton-svg.vue'
import { useMonthlyStudyPodcastStore } from '@TodayInTheWord/stores/monthly-study/monthly-study-podcast-store'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { PodcastProps, PodcastsSectionProps } from '../types/devotional-monthly-study-types'
import PodcastList from './podcast-list.vue'
import PodcastPlayer from './podcast-player.vue'

const podcastStore = useMonthlyStudyPodcastStore()
const { isLoadingPodcastList } = storeToRefs(podcastStore)

interface podcastsSectionProps {
  model?: PodcastsSectionProps
}

const props = defineProps<podcastsSectionProps>()
const isMounted = ref(false)

onMounted(async () => {
  isMounted.value = true
  await podcastStore.loadPodcastListAsync(props.model?.dataUrl)
})
</script>
