<template>
  <div>
    <button
      v-show="isCurrentPodcastPlaying || !isCurrentPodcastPlayRequested"
      @click="podcastStore.playOrPause(props.podcast)"
      class="bg-light-blue-900 hover:bg-light-blue-700 mr-3 rounded-xl p-2 transition lg:p-3"
    >
      <span v-if="!isCurrentPodcastPlaying" class="flex items-center justify-center">
        <PlayIcon class="h-6 w-6 text-white" />
      </span>
      <span v-if="isCurrentPodcastPlaying" class="flex items-center justify-center">
        <PauseIcon class="h-6 w-6 text-white" />
      </span>
    </button>
    <div
      v-show="!isCurrentPodcastPlaying && isCurrentPodcastPlayRequested"
      class="bg-light-blue-900 hover:bg-light-blue-700 mr-3 rounded-xl p-2 transition lg:p-3"
      style="width: 48px; height: 48px"
    >
      <span class="flex items-center justify-center">
        <s-loading-dots />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PauseIcon, PlayIcon } from '@heroicons/vue/24/solid'
import { isDefined } from '@shared/api/type-utilities'
import sLoadingDots from '@TodayInTheWord/components/shared/s-loading-three-dots.vue'
import { useMonthlyStudyPodcastStore } from '@TodayInTheWord/stores/monthly-study/monthly-study-podcast-store'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { PodcastProps } from '../types/devotional-monthly-study-types'

interface PlayPauseButtonProps {
  podcast?: PodcastProps | null
}

const props = defineProps<PlayPauseButtonProps>()

const podcastStore = useMonthlyStudyPodcastStore()
const { isPlaying, isPlayRequested, streamUrl } = storeToRefs(podcastStore)

const isCurrentPodcastPlaying = computed(() => {
  if (isDefined(props.podcast?.streamUrl) && isDefined(streamUrl.value)) {
    return isPlaying.value && streamUrl.value === props.podcast.streamUrl
  }

  return isPlaying.value
})

const isCurrentPodcastPlayRequested = computed(() => {
  if (isDefined(props.podcast?.streamUrl) && isDefined(streamUrl.value)) {
    return isPlayRequested.value && streamUrl.value === props.podcast.streamUrl
  }

  return isPlayRequested.value
})
</script>
