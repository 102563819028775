<template>
  <div v-if="model && state.isOpen" class="bg-light-blue-700 py-2" v-bind:id="model.clientID">
    <div class="container mx-auto">
      <div class="flex justify-between space-x-4">
        <div class="flex flex-1 space-x-7 text-sm md:text-base">
          <span class="flex h-5 items-center md:h-6" role="img" :aria-label="'Not Urgent Message'">
            <bell-icon class="h-5 w-5 stroke-white"></bell-icon>
          </span>
          <div class="flex flex-col space-y-2">
            <template v-for="(alert, index) in model.alerts" :key="index">
              <div v-if="state.activeAlert === alert.model.alertCookieName">
                <span
                  v-if="alert.model.alertText"
                  class="text-sm font-medium text-white lg:text-base"
                  >{{ alert.model.alertText }}</span
                >
                <s-link
                  v-if="alert.model.alertLink && alert.model.alertLink.href"
                  :href="alert.model.alertLink.href"
                  :title="alert.model.alertLink.title"
                  :external-link="alert.model.alertLink.isExternal"
                  :target="alert.model.alertLink.target"
                  class="ml-1 text-white underline"
                >
                  {{ alert.model.alertLink.text }}
                </s-link>
              </div>
            </template>
          </div>
        </div>
        <button class="flex h-5 items-center md:h-6" @click="closeAlert()">
          <span class="sr-only">Close Alert</span>
          <x-mark-icon class="h-5 w-5 stroke-white"></x-mark-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { isDefined } from '@shared/api/type-utilities'
import {
  AlertProps,
  SiteAlertBlockProps,
} from '@TodayInTheWord/components/blocks/site-alert/types/site-alert-block-types'
import { useCookiesStore } from '@TodayInTheWord/stores/cookies'
import { onMounted, ref } from 'vue'

const props = defineProps<SiteAlertBlockProps>()
const cookieStore = useCookiesStore()

type SiteAlertBlockState = {
  isOpen: boolean
  activeAlert?: string | null
}

const state = ref<SiteAlertBlockState>({
  isOpen: false,
  activeAlert: null,
})

const closeAlert = () => {
  cookieStore.setCookie(`alert-titwAlert${state.value.activeAlert}`, 'accepted')

  if (!isDefined(props.model?.alerts)) {
    state.value.isOpen = false
    state.value.activeAlert = undefined
    return
  }

  state.value.activeAlert = props.model.alerts.find(
    (alert: AlertProps) => !cookieStore.getCookie(`alert-titwAlert${alert.model.alertCookieName}`),
  )?.model.alertCookieName

  state.value.isOpen = isDefined(state.value.activeAlert)
}

onMounted(() => {
  if (!isDefined(props.model?.alerts)) {
    state.value.isOpen = false
    state.value.activeAlert = undefined
    return
  }

  state.value.activeAlert = props.model.alerts.find(
    (alert: AlertProps) => !cookieStore.getCookie(`alert-titwAlert${alert.model.alertCookieName}`),
  )?.model.alertCookieName

  state.value.isOpen = isDefined(state.value.activeAlert)
})
</script>
