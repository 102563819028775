<template>
  <section v-if="model" v-bind:id="model.clientID">
    <div class="TITW featured-media-block container relative mx-auto">
      <div v-if="model.sectionHeading || model.sectionSubheading" class="mb-5 lg:mb-6">
        <section-heading
          :heading="model.sectionHeading"
          :subheading="model.sectionSubheading"
          :sub-heading-classes="'text-grey-700'"
        />
      </div>
      <div
        class="lg:rounded-4xl overflow-hidden rounded-3xl lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:items-center lg:gap-8"
        :class="{
          'bg-grey-100': model.backgroundColor !== 'white',
          'p-3 pb-6 lg:p-14': model.size === 'standard',
          'p-3 pb-6 lg:p-24': model.size === 'large',
          'p-3 pb-6 lg:p-32': model.size === 'extra large',
          'p-0 lg:p-0': model.size === 'no padding',
        }"
      >
        <div
          class="relative mb-4 w-full lg:mb-0"
          :class="{
            'order-5': model.imagePosition === 'right',
          }"
        >
          <picture
            v-if="model.image"
            class="flex"
            :class="{
              'lg:aspect-[16/9]': model.imageAspectRatio === '16/9',
              'lg:aspect-[3/2]': model.imageAspectRatio === '3/2',
              'lg:aspect-[1/1]': model.imageAspectRatio === '1/1',
            }"
          >
            <shared-image
              :model="model.image"
              class="h-full w-full rounded-xl object-cover lg:rounded-2xl"
              format="?width=592&height=333&format=png&oldformat=webp&compand=true"
            />
          </picture>
          <template v-if="model.mediaType === 'video'">
            <s-button
              v-if="model.videoShareLink || model.videoUrl"
              ref="playButton"
              :size="'regular'"
              :emphasis="'bold'"
              :style="'primary'"
              classOverride="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center h-[72px] w-[72px]"
              @click="openModal()"
            >
              <span class="sr-only">Play Video</span>
              <span class="flex h-6 w-6 items-center justify-center">
                <PlayIcon class="w-full" />
              </span>
            </s-button>
          </template>
        </div>
        <div
          class="w-full"
          :class="{
            'order-0': model.imagePosition === 'right',
            'px-3 pb-6 lg:px-3 lg:pb-0': model.size === 'no padding',
          }"
        >
          <h2
            v-if="model.heading"
            class="mb-4 text-2xl font-semibold"
            :class="computedHeadingClasses"
          >
            {{ model.heading }}
          </h2>

          <p
            v-if="model.description"
            class="mb-4 text-lg"
            :class="{
              'md:text-base': model.size === 'standard',
              'md:mb-5 md:text-lg': model.size === 'large',
              'md:mb-5 md:text-xl': model.size === 'extra large',
              'md:mb-5': model.size === 'no padding',
            }"
          >
            {{ model.description }}
          </p>

          <s-link
            v-if="model.buttonLink && model.buttonLink.href"
            :href="model.buttonLink.href"
            :target="model.buttonLink.target"
            :size="model.buttonLink.buttonSize"
            :emphasis="model.buttonLink.buttonEmphasis"
            :style="model.buttonLink.buttonStyle"
            :external-link="model.buttonLink.isExternal"
          >
            {{ model.buttonLink.text }}
          </s-link>
        </div>
      </div>
    </div>
    <c2-modal
      v-if="model.videoShareLink || model.videoUrl"
      ref="featuredImageVideoModal"
      :background-color="'transparent'"
      :background-overlay-color="'rgba(0,0,0,0.8)'"
      :close-btn-classes="'flex items-center justify-center h-12 w-12 absolute top-0 right-0'"
      :header-classes="'flex items-end justify-between min-h-[48px] px-4 relative'"
      :wrapper-classes="'bg-white p-4'"
      :max-width="1296"
      class="relative h-0 overflow-hidden"
      @modal-opened="toggleShowVideo(true)"
      @modal-closed="toggleShowVideo(false)"
    >
      >
      <template v-slot:close-btn>
        <span class="sr-only">Close Modal</span>
        <span class="h-6 w-6"><XMarkIcon class="text-white" /></span>
      </template>

      <template v-slot:body>
        <div
          v-if="model.videoShareLink"
          class="iframe-body relative aspect-[16/9] w-full overflow-hidden rounded-xl bg-white"
          v-html="model.videoShareLink"
        ></div>
      </template>
    </c2-modal>
    <template v-if="model.videoShareScript">
      <div id="featured-custom-video-script"></div>
    </template>
  </section>
</template>

<script setup lang="ts">
import { PlayIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import SectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import C2Modal from 'uikit/c2-modal/c2-modal.vue'
import { computed, ref } from 'vue'
import { FeaturedImageVideoProps } from './types/featured-image-video-block-types'

const props = defineProps<FeaturedImageVideoProps>()

const featuredImageVideoModal = ref()
const playButton = ref()
const videoShareLink = ref(true)

function openModal() {
  featuredImageVideoModal.value?.openModal()
}

const computedHeadingClasses = computed(() => {
  if (props.model?.size) {
    if (props.model.size === 'large') {
      return 'md:mb-5 md:text-3xl'
    } else if (props.model.size === 'extra large') {
      return 'md:mb-5 md:text-4xl'
    } else {
      return 'md:mb-5 md:text-3xl'
    }
  } else {
    return ''
  }
})

const toggleShowVideo = (bool: boolean) => {
  videoShareLink.value = bool

  if (videoShareLink.value) {
    createScript()
  } else {
    pauseAndReturnFocus()
  }
}
const createScript = () => {
  //clear any previous scripts
  var prevScript = document.querySelector('#featured-custom-video-script')
  if (prevScript !== null) {
    prevScript.innerHTML = ''
    var script = document.createElement('script')
    prevScript.appendChild(script)
    if (props.model?.videoShareScript) {
      script.innerHTML = props.model?.videoShareScript
    }
  }
}
const pauseAndReturnFocus = () => {
  const videos = [...document.getElementsByTagName('video')]
  videos.forEach((vid) => {
    vid.pause()
  })
  const iframeVideo = featuredImageVideoModal.value?.$el.querySelector('iframe')
  if (iframeVideo) {
    let src = iframeVideo.src
    iframeVideo.src = src
  }

  playButton.value?.$el.focus()
}
</script>

<style lang="scss">
.c2-modal {
  .iframe-body {
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
