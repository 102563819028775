<template>
  <s-link
    v-if="card.link"
    :href="card.link.href"
    :external-link="card.link.isExternal"
    class="group flex w-1/3 flex-col transition-all duration-300 ease-in-out"
  >
    <div
      class="bg-light-blue-100 relative aspect-video w-full flex-shrink-0 overflow-hidden rounded-xl transition-all duration-300 ease-in-out group-hover:shadow-lg"
    >
      <shared-image
        v-if="card.image"
        :model="card.image"
        class="h-full w-full object-cover"
        format="?width=540&height=276&format=png&oldformat=webp&compand=true"
      />
      <div
        v-if="isDateWithinOneYear(card.displayDate)"
        class="absolute bottom-2 left-2 w-fit rounded-lg bg-black/60 px-2 py-1 text-xs text-white"
      >
        {{ card.displayDate }}
      </div>
    </div>
    <div
      v-if="card.title || card.description || card.duration"
      class="flex h-full w-full flex-col justify-between space-y-2 p-2"
    >
      <div>
        <div :class="{ 'flex items-center justify-between': card.duration }">
          <span
            v-if="!props.options?.hideTitle && card.title"
            class="text-grey-800 group-hover:text-light-blue-600 line-clamp-2 text-base font-medium transition-all duration-300"
          >
            {{ card.title }}
          </span>
          <div v-if="card.duration" class="text-grey-600 text-xs font-normal leading-none">
            {{ card.duration }}
          </div>
        </div>
        <div
          v-if="!props.options?.hideDescription && card.description"
          class="text-grey-700 mt-2 line-clamp-4 text-sm font-normal"
        >
          {{ card.description }}
        </div>
      </div>
      <div
        v-if="card.authors"
        class="flex"
        :class="card.authors || card.authorName ? 'items-center justify-between' : 'justify-end'"
      >
        <template v-if="card.authors">
          <span
            v-for="(author, index) in card.authors"
            :key="index"
            class="text-grey-700 text-xs font-medium"
            >{{ author.displayName }}</span
          >
        </template>

        <span v-else-if="card.authorName" class="text-grey-700 text-xs font-medium">{{
          card.authorName
        }}</span>

        <tag
          v-if="!props.options?.hideTag && (card.articleType || card.type)"
          class="rounded-xl px-2 py-0.5 text-xs"
          :tag="cardType"
        />
        <div
          v-else-if="card.categories && card.categories.length > 0"
          class="bullet-seperator flex items-center space-x-1 [&>:not(:first-child)]:before:mr-1"
        >
          <span
            v-if="!props.options?.hideCategory"
            v-for="(category, index) in computedCategories"
            :key="index"
            class="text-grey-500 text-xs font-normal"
          >
            {{ category }}
          </span>
        </div>
      </div>
    </div>
  </s-link>
</template>

<script setup lang="ts">
import SharedImage from '@TodayInTheWord/components/shared/s-image.vue'
import { isDateWithinOneYear } from '@TodayInTheWord/scripts/date-util'
import { ImageLinkCardDisplayOptions, ImageLinkCardProps } from '@TodayInTheWord/types/shared'
import { computed } from 'vue'
import Tag from '../shared/tag.vue'

interface SImageLinkCardProps {
  card: ImageLinkCardProps
  dateFormat?: string
  options?: ImageLinkCardDisplayOptions
}

const props = defineProps<SImageLinkCardProps>()

const cardType = computed(() => {
  if (props.card.articleType) {
    return props.card.articleType
  } else {
    return props.card.type
  }
})

const computedCategories = computed(() => {
  return props?.card?.categories?.slice(0, 2)
})
</script>
