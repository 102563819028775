﻿/**
 * Will check the content's content type against the inheritance chain list in
 * components.
 *
 * Used to get the Vue component matching the loaded content's type by
 * `BlockComponentSelector` and `PageComponentSelector`.
 *
 * @param {*} content The content object that has a contentType property, which
 * holds the inheritance chain from the C# models for the content with the last
 * item being the actual implementation.
 * @param {{[key:string]: any}} components The list of registered Vue components.
 * @returns The matching content type, or `null`.
 */
export function getComponentTypeForContent(content, components) {
  return getComponentTypeForContentType(content?.contentType, components)
}

/**
 * Will check the content's content type against the inheritance chain list in
 * components.
 *
 * Used to get the Vue component matching the loaded content's type by
 * `BlockComponentSelector` and `PageComponentSelector`.
 *
 * @param {string[]} contentType The string array which
 * holds the inheritance chain from the C# models for the content with the last
 * item being the actual implementation.
 * @param {{[key:string]: any}} components The list of registered Vue components.
 * @returns The matching content type, or `null`.
 */
export function getComponentTypeForContentType(contentType, components) {
  if (!contentType) {
    return null
  }

  if (!components) {
    return null
  }

  // Here we will try to find a component that matches the content type name.
  for (let i = contentType.length - 1; i >= 0; i--) {
    const currentContentType = contentType[i]
    const component = components[currentContentType]

    if (component) {
      return component
    }
  }
  return null
}
