import * as webApi from '@shared/api/web-api'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { TitwSearchAsYouTypeResultsModel } from '@TodayInTheWord/stores/search-page/search-types'
import { defineStore } from 'pinia'
import { HeaderState, SiteHeaderModel } from './header-types'

const STORE_ID = 'HEADER_STORE'

const defaultState: HeaderState = {
  header: null,
  errorMessage: '',
  searchResponse: null,
  isLoading: false,
  loadedResults: false,
  searchText: '',
  menuState: {
    isDesktopAccountMenuOpen: false,
    isDesktopLanguageMenuOpen: false,
    isMobileAccountMenuOpen: false,
    isMobileLanguageMenuOpen: false,
    isMobileMenuOpen: false,
    isMobileSearchOpen: false,
  },
  noResultsMessage: '',
}

export const useHeaderStore = defineStore(STORE_ID, {
  state: (): HeaderState => defaultState,
  getters: {
    isMobile(state: HeaderState): boolean {
      const mediaSizeStore = useMediaSizeStore()
      return mediaSizeStore.isMobileOrTablet
    },
    showLanguageMenu(state: HeaderState): boolean {
      const languageMenu = state?.header?.languageMenu
      return (
        (languageMenu && languageMenu.isEnabled && (languageMenu.items?.length ?? 0) > 0) ?? false
      )
    },
    showAccountMenu(state: HeaderState): boolean {
      return state?.header?.accountMenu?.isEnabled === true
    },
    isSearchResponseValid(state: HeaderState): boolean {
      return (
        state.searchText !== '' &&
        state.searchResponse !== null &&
        state.searchText === state.searchResponse.query
      )
    },
    hasSearchResults(state: HeaderState): boolean {
      const searchResponseHasResults = state.searchResponse?.hasResults ?? false
      const isInvalidQuery = state.searchResponse?.isInvalidQuery ?? false
      return this.isSearchResponseValid && searchResponseHasResults && !isInvalidQuery
    },
  },
  actions: {
    async loadHeader() {
      this.errorMessage = ''
      this.isLoading = true
      const headerResponse = await webApi.callAsync<SiteHeaderModel>({
        method: 'post',
        url: '/header',
        headers: { Accept: 'application/json' },
      })

      this.isLoading = false
      this.errorMessage = headerResponse.errorMessage
      if (headerResponse.success && headerResponse.data) {
        this.header = headerResponse.data
      }
    },
    clearSearch() {
      this.searchText = ''
      this.searchResponse = null
      this.loadedResults = false
    },
    async search() {
      if (!this.searchText) {
        this.searchResponse = null
      }

      if (this.searchText.length < 3 && !this.searchResponse) {
        return
      }

      if (this.searchText === this.searchResponse?.query) {
        return
      }

      this.searchResponse = null
      this.isLoading = true
      let url = this.header?.searchPageLink ?? '/search'
      if (url?.endsWith('/')) {
        url = url.substring(0, url.length - 1)
      }

      const response = await webApi.callAsync<TitwSearchAsYouTypeResultsModel>({
        method: 'post',
        url: `${url}/SearchAsYouType`,
        headers: { Accept: 'application/json' },
        params: { q: this.searchText },
      })

      this.isLoading = false

      if (response.success) {
        this.searchResponse = response.data ?? null
      } else {
        this.searchResponse = {
          query: this.searchText,
          hasResults: false,
          errorMessage: response.errorMessage,
          devotions: null,
          otherPages: null,
          autocomplete: null,
          relatedQueries: null,
          isInvalidQuery: false,
        }
      }

      this.loadedResults = true
    },
    async updateSearchText(term: string) {
      this.searchText = term
      await this.search()
    },
    setInitialState(this: HeaderState, data: any) {
      if (!data) {
        return
      }

      if (data.siteLayout?.header) {
        this.header = data.siteLayout.header
      } else if (data.header) {
        this.header = data.header
      }
    },
  },
})
