import { MaybeRefOrGetter, toValue } from 'vue'

export function formatAudioTime(
  timeInSeconds: MaybeRefOrGetter<number | null | undefined>,
): string {
  const time = toValue(timeInSeconds) ?? 0
  if (!time || time === null) {
    return ''
  }

  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}
