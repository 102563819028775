<template>
  <div class="TITW form-container" id="animateSuccess-js">
    <div :id="`formContainer_${formContentLinkId}`" ref="formElement" />
  </div>
</template>

<script setup lang="ts">
import formRenderingService from '@TodayInTheWord/lib/forms/form-rendering-service'
import { onMounted, onUpdated, ref } from 'vue'

export interface FormContainerBlockProps {
  model?: {
    contentLink: string
    formModelRawData: string
  }
}
const props = defineProps<FormContainerBlockProps>()

const formContentLinkId = props.model?.contentLink
const formElement = ref(null)

const renderForm = async () => {
  if (!formContentLinkId) {
    return
  }

  const formModel = JSON.parse(props.model?.formModelRawData)
  formRenderingService.render(formModel, formElement.value)
}
const removeParentDisplayNone = () => {
  console.log(`formContainer_${formContentLinkId}`)
  const formContainer = document.getElementById(`formContainer_${formContentLinkId}`)
  if (formContainer && formContainer.parentElement) {
    setTimeout(() => {
      formContainer.parentElement && (formContainer.parentElement.style.display = '')
    }, 250)
  }
}

onMounted(async () => {
  await renderForm()
  removeParentDisplayNone()
})

onUpdated(async () => {
  await renderForm()
  removeParentDisplayNone()
})
</script>
<style lang="scss">
.EPiServerForms {
  .Form__Status {
    .Form__Status__Message {
      &.hide {
        visibility: hidden;
        opacity: 0;
        transition:
          visibility 0s 1s,
          opacity 5s linear;
      }
    }
  }
}

.form-container {
  background-color: theme('colors.grey.50');
  border-radius: 24px;
  padding: 32px;

  button,
  input[type='text'],
  select {
    border: 1px solid theme('colors.grey.200');
  }

  svg * {
    fill: grey;
  }

  &.centered {
    .EPiServerForms {
      h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        margin: 0 0 24px 0;
        color: theme('colors.grey.700');
      }

      > .Form__MainBody {
        > section {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          gap: 24px;
          margin: 0;

          > .Form__Element {
            flex: 0 0 100%;

            &.FormSubmitButton,
            &.FormResetButton {
              width: 100%;
              flex-basis: auto;
            }
          }
        }
      }
    }
  }

  .EPiServerForms {
    h2 {
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      margin: 0 0 24px 0;
      color: theme('colors.grey.700');
    }

    > .Form__MainBody {
      > section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        gap: 24px;
        margin: 0;

        > .Form__Element {
          flex: 0 0 100%;

          &.FormSubmitButton,
          &.FormResetButton {
            flex-basis: auto;
          }
        }
      }

      &.hide {
        display: none !important;
      }
    }

    .Form__Description {
      color: theme('colors.grey.700');
    }

    .Form__Description,
    .Form__Status {
      margin: 0 0 24px 0;
    }

    .FormRange__Max,
    .FormRange__Min {
      color: theme('colors.grey.700');
    }

    .FormRange__Max {
      margin-left: 4px;
    }

    .FormRange__Min {
      margin-right: 4px;
    }

    .Form__Status {
      .Form__Status__Message {
        background-color: transparent;
        color: theme('colors.grey.700');
        margin: 0;
        padding: 0;

        &.Form__Warning__Message {
          border-radius: 12px;
          background-color: theme('colors.amber.100');
          color: theme('colors.amber.700');
          padding: 8px;
        }
      }
    }

    .Form__Element__Caption {
      width: auto !important;
    }

    .Form__Element {
      margin: 0;

      &.FormTextbox,
      &.FormSelection,
      &.FormRange {
        gap: 0;
      }

      .centered & {
        flex-direction: column;

        .FormSubmitButton {
          margin-top: 16px;
          margin-left: 0;
        }
      }

      &.FormChoice {
        fieldset {
          display: flex;
          flex-direction: column;
          gap: 12px;

          div {
            display: flex;
            align-items: center;
            gap: 12px;
          }
        }
      }

      &.FormRange {
        > span {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
      }

      &.ValidationRequired {
        .Form__Element__Caption {
          &::after {
            content: '*';
          }

          &:empty::after {
            content: '';
          }
        }
      }

      &.ValidationFail {
        input[type='text'],
        input[type='password'],
        input[type='number'],
        input[type='date'],
        input[type='datetime-local'],
        input[type='email'],
        input[type='month'],
        input[type='search'],
        input[type='tel'],
        input[type='url'],
        textarea,
        select {
          box-shadow: 0px 0px 0px 3px rgba(244, 63, 94, 0.25);
          border-color: theme('colors.grey.200');
        }

        .Form__Element__Caption {
          color: theme('colors.red.600');
        }

        .FormChoice__Label {
          color: theme('colors.red.600');
        }
      }
    }

    .Form__Element__Caption {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .FormSubmitButton,
    .FormResetButton {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      height: 48px;
      padding: 8px 14px;
      border-radius: 12px;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      cursor: pointer;
    }

    .FormSubmitButton {
      background-color: theme('colors.light-blue.900');
      border: 1px solid theme('colors.light-blue.900');
      color: theme('colors.white');
    }

    .FormResetButton {
      background-color: theme('colors.light-blue.100');
      border: 1px solid theme('colors.light-blue.100');
      color: theme('colors.light-blue.900');
    }

    .FormSubmitButton:hover,
    .FormResetButton:hover {
      transition: 0.3s all;
      border-color: theme('colors.light-blue.700');
      background-color: theme('colors.light-blue.700');
    }

    .FormResetButton:hover {
      color: theme('colors.white');
    }

    .Form__Element__ValidationError {
      display: block;
      margin-top: 4px;
      font-size: 12px;
      line-height: 20px;
      color: theme('colors.red.600');
    }

    select,
    input {
      width: 100%;
      padding: 12px;
      border: 1px solid theme('colors.grey.200');
      border-radius: 12px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      background-color: theme('colors.white');

      &:focus,
      &:focus-visible {
        box-shadow: 0px 0px 0px 3px rgba(2, 132, 199, 0.3);
        outline: none;
      }
    }

    select:not([multiple]),
    input {
      height: 48px;
    }

    textarea {
      width: 100%;
      height: 144px;
      padding: 12px;
      border: 1px solid theme('colors.grey.200');
      border-radius: 12px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      background-color: theme('colors.white');

      &:focus,
      &:focus-visible {
        box-shadow: 0px 0px 0px 3px rgba(2, 132, 199, 0.3);
        outline: none;
      }
    }

    input[type='range'] {
      flex: 1 1 100%;
      max-width: 100%;
    }

    input[type='radio'] {
      -webkit-appearance: none;
      appearance: none;
      min-width: 16px;
      width: 16px;
      height: 16px;
      padding: 0;
      vertical-align: middle;
      border-radius: 50%;
      border: 1px solid theme('colors.grey.200');

      &:checked {
        background-color: theme('colors.light-blue.700');
      }
    }

    input[type='checkbox'] {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      padding: 0;
      vertical-align: middle;
      border-radius: 4px;
      border: 1px solid theme('colors.grey.200');

      &:checked {
        background-color: theme('colors.light-blue.700');

        &:before {
          content: '';
          display: block;
          background-size: 13px 13px;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml; utf8, %3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.20017 6.33959L1.05017 3.18959L0.420166 3.81959L3.57017 6.96959L4.20017 7.59959L10.5902 1.20959L9.96017 0.57959L4.20017 6.33959Z' fill='white'/%3E%3C/svg%3E%0A");
        }
      }
    }

    select {
      width: 100%;
      padding: 12px;
      border: 1px solid theme('colors.grey.200');
      border-radius: 12px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      background-color: theme('colors.white');
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.097631 1.31658 -0.097631 1.70711 0.292893L5 3.58579L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z' fill='%23A1A1AA'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat, repeat;
      background-position:
        right 0.7em top 50%,
        0 0;
      background-size:
        0.65em auto,
        100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;

      &:focus,
      &:focus-visible {
        box-shadow: 0px 0px 0px 3px rgba(2, 132, 199, 0.3);
        outline: none;
      }

      &:not([multiple]) {
        height: 48px;
      }

      &[multiple] {
        background-image: unset;
      }
    }
  }

  [class~='FormChoice'] {
    flex-wrap: wrap;

    span[class='Form__Element__ValidationError'] {
      width: 100%;
    }
  }
}

@media print {
  .grecaptcha-badge {
    display: none !important;
  }
}
</style>
