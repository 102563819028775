<template>
  <div
    class="mx-auto mt-5 w-full space-y-8 detail-container lg:max-w-[856px] lg:space-y-12"
    :class="{ container: mediaSizeStore.isMobileOrTablet }"
  >
    <div>
      <bread-crumbs
        :links="breadcrumbs.links"
        :currentPageName="breadcrumbs.currentPageName"
        class="mt-4 lg:mt-6"
      />
    </div>
    <div class="border-b border-slate-200 pb-8 lg:pb-12">
      <div
        v-if="model.featuredImage"
        class="mb-4 aspect-video lg:mb-6 lg:overflow-hidden lg:rounded-xl"
        :class="{ '-m-[18px]': mediaSizeStore.isMobileOrTablet }"
      >
        <s-image
          v-if="model.featuredImage"
          :model="model.featuredImage"
          class="h-full w-full object-cover"
          :preload="true"
        />
      </div>
      <div
        v-if="model.articleType || model.displayDate"
        class="mb-3 flex justify-between lg:mb-4 lg:justify-normal lg:space-x-4"
      >
        <tag
          v-if="model.articleType"
          class="flex items-center rounded-xl px-2 py-0.5 text-xs"
          :tag="{ displayName: model.articleType, tagColor: 'light-blue' }"
        />
        <span class="text-grey-600 text-base font-normal">
          {{ model.displayDate }}
        </span>
      </div>
      <div class="flex flex-col space-y-1 lg:space-y-2">
        <h1 v-if="model.title" class="text-2xl font-semibold text-cyan-900 lg:text-4xl">
          {{ model.title }}
        </h1>
        <p v-if="model.subtitle" class="text-grey-700 text-base font-normal lg:text-xl">
          {{ model.subtitle }}
        </p>
      </div>
    </div>
    <div class="space-y-6 lg:space-y-10">
      <srtf
        v-if="model.mainBody"
        :has-container="false"
        :model="{ richTextField: model.mainBody }"
      />
      <div class="relative flex space-x-1">
        <share-this />
        <button class="printButton bg-transparent p-4" @click.prevent="handlePrint">
          <PrinterIcon class="stroke-light-blue-900 hover:stroke-light-blue-600 h-6 w-6" />
        </button>
      </div>
    </div>
    <AboutTheAuthor :model="model.aboutTheAuthors" />
    <RelatedContent :model="model.relatedContent" />
  </div>
</template>

<script setup lang="ts">
import { PrinterIcon } from '@heroicons/vue/24/outline'
import Srtf from '@TodayInTheWord/components/blocks/rich-text-block/rich-text-block.vue'
import AboutTheAuthor from '@TodayInTheWord/components/pages/shared/about-the-author.vue'
import RelatedContent from '@TodayInTheWord/components/pages/shared/related-content.vue'
import BreadCrumbs from '@TodayInTheWord/components/shared/breadcrumbs.vue'
import SImage from '@TodayInTheWord/components/shared/s-image.vue'
import ShareThis from '@TodayInTheWord/components/shared/share-this/s-share-this-button.vue'
import Tag from '@TodayInTheWord/components/shared/tag.vue'
import { useCookiesStore } from '@TodayInTheWord/stores/cookies'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useMediaSizeStore } from '@TodayInTheWord/stores/media-size'
import { BreadcrumbProps } from '@TodayInTheWord/types/shared'
import { ref } from 'vue'
import { ArticleDetailPageProps } from '../article-index/types/article-detail-types'

const model: ArticleDetailPageProps['model'] = useEpiPageContextStore().model
const breadcrumbs: BreadcrumbProps = useEpiPageContextStore().breadcrumbs
const mediaSizeStore = useMediaSizeStore()
const cookiesStore = useCookiesStore()

const state = ref({
  articleSaved: false,
})

const handlePrint = () => {
  window.print()
}
</script>

<style scoped>
@media screen and (min-width: 1023px) and (max-width: 1024px) {
  .detail-container {
    max-width: 768px;
  }
}
</style>
